import React from "react";
import styled from "styled-components";
import SecondaryButton from "../Buttons/SecondaryButton";

const BarWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 778px;
  height: 85px;
  background-color: #fff;
  border-radius: 12px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 95%;
    height: auto;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
  }
`;

const CountText = styled.span`
  font-weight: 600;
  font-size: 16px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  color: #666;

  &:hover {
    color: #000;
  }
`;

const BottomBulkActionBar = ({ count, onDeleteClick, onClose, translate }) => {
  if (count === 0) return null;

  return (
    <BarWrapper>
      <CountText>
        {translate("domain-templates.table.selected")} {count}{" "}
      </CountText>

      <ActionsWrapper>
        <SecondaryButton
          style={{ padding: "8px 18px", fontSize: "14px" }}
          onClick={onDeleteClick}
        >
          {translate("domain-templates.table.dropdown-menu.delete-template")}
        </SecondaryButton>
        <CloseButton onClick={onClose}>
          &#10005;
        </CloseButton>
      </ActionsWrapper>
    </BarWrapper>
  );
};

export default BottomBulkActionBar;
