import axios from "axios";
import { sprintf } from "sprintf-js";

import {
  CUSTOMER_DOMAINS_API_ENDPOINT,
  CUSTOMER_DOMAINS_CREATE_OPERATION,
  CUSTOMER_DOMAINS_UPDATE_OPERATION,
  CUSTOMER_DOMAINS_WITHOUT_HOSTING_API_ENDPOINT,
  CUSTOMER_PRODUCTS_API_ENDPOINT,
  CUSTOMER_PRODUCTS_PRODUCT_SERVICES_API_ENDPOINT,
  CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_API_ENDPOINT,
  CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_API_ENDPOINT,
  CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_CHANGE_PASSWORD_API_ENDPOINT,
  CUSTOMER_SERVERS_API_ENDPOINT,
  CUSTOMER_PRODUCTS_SERVER_SERVICES_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_CANCEL_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_AUTORENEW_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_BY_NAME_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_UNLOCK_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_LOCK_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_LOCKED_STATUS_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_TRANSFER_IN_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_GET_EPP_TRANSFER_CODE_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_NAMESERVERS_UPDATE,
  CUSTOMER_CONTACTS_API_ENDPOINT,
  CUSTOMER_CONTACTS_CHANGE_DOMAIN_CONTACT,
  CUSTOMER_CONTACTS_ASSIGN_PRODUCT_CONTACT,
  CUSTOMER_CONTACTS_CONTACT_DETAILS,
  CUSTOMER_CONTACTS_UNASSIGN_PRODUCT_FROM_CONTACT_API_ENDPOINT,
  CUSTOMER_CONTACTS_ACCEPT_INVITATION,
  CUSTOMER_CONTACTS_REGISTER_ASSISTANT,
  SEARCH_CONTACTS_API_ENDPOINT,
  SEARCH_PAYERS_API_ENDPOINT,
  CUSTOMER_DOMAINS_FILTER_STATUS_COLLECTION_API_ENDPOINT,
  CUSTOMER_DOMAINS_FILTER_EXTENSIONS_COLLECTION_API_ENDPOINT,
  CUSTOMER_DOMAINS_FILTER_REGISTER_CONTACTS_COLLECTION_API_ENDPOINT,
  CUSTOMER_PRODUCTS_FILTER_STATUS_COLLECTION_API_ENDPOINT,
  CUSTOMER_PRODUCTS_FILTER_PRODUCTS_COLLECTION_API_ENDPOINT,
  CUSTOMER_SERVERS_FILTER_STATUS_COLLECTION_API_ENDPOINT,
  CUSTOMER_SERVERS_FILTER_PRODUCTS_COLLECTION_API_ENDPOINT,
  CUSTOMER_PRODUCTS_PRODUCT_DETAILS,
  CUSTOMER_PRODUCTS_SERVER_DETAILS,
  CUSTOMER_PAYERS_FOR_FILTER_API_ENDPOINT,
  CUSTOMER_PERSONAL_INFORMATION_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_SERVICES_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_API_ENDPOINT,
  CUSTOMER_EMAILS_API_ENDPOINT,
  CUSTOMER_EMAILS_EMAIL_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_REQUEST_RENEWAL_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_NAME_SERVERS_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_REGISTER_CONTACTS_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_ZONE_FILE_API_ENDPOINT,
  CUSTOMER_DOMAINS_CREATE_DOMAIN_ZONE_FILE_API_ENDPOINT,
  CUSTOMER_DOMAINS_DELETE_DOMAIN_ZONE_FILE_API_ENDPOINT,
  CUSTOMER_DOMAINS_RESET_DOMAIN_ZONE_FILE_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_ZONE_ADD_RECORD_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_ZONE_UPDATE_RECORD_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_ZONE_DELETE_RECORD_API_ENDPOINT,
  CUSTOMER_UPDATE_LANGUAGE_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_REDIRECT_AVAILABLE_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_GET_REDIRECTION_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_REDIRECTION_UPDATE_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_REDIRECTION_DELETE_API_ENDPOINT,
  CUSTOMER_DOMAINS_DOMAIN_BUY_ID_PROTECTION,
  CUSTOMER_PRODUCTS_PRODUCT_HOSTING_BACKUP_API_ENDPOINT,
  CUSTOMER_PRODUCTS_PRODUCT_HOSTING_BACKUP_HISTORY_API_ENDPOINT,
  CUSTOMER_PRODUCTS_PRODUCT_HOSTING_BACKUP_RESTORE_API_ENDPOINT,
  CUSTOMER_CREATE_DOMAIN_TEMPLATE,
  CUSTOMER_GET_LIST_DOMAIN_TEMPLATES,
  CUSTOMER_GET_DOMAIN_TEMPLATE,
  CUSTOMER_DOWNLOAD_DOCUMENT,
  CUSTOMER_UPLOAD_DOCUMENT,
  CUSTOMER_UPLOAD_DOCUMENT_V2,
  CUSTOMER_DOMAINS_TEMPLATES_ASSIGN,
  CUSTOMER_ASSIGN_ITEM_DELIVERY_TYPE,
  CUSTOMER_FETCH_DATA_FROM_UJP,
  CUSTOMER_GET_TEMPLATE_BY_ID,
  CUSTOMER_SIGN_DOCUMENT_EVROTRUST,
  CUSTOMER_CHECK_DOCUMENT_EVROTRUST,
  CUSTOMER_TEMPLATE_ADD_TEKOVNA,
  CUSTOMER_ATTACH_COMPANY_CERTIFICATE,
  CUSTOMER_TEMPLATE_DELETE_TEMPLATE,
  CUSTOMER_TEMPLATE_UPDATE_TEMPLATE
} from "./endpoints";

import { SORT_ORDER_DESCENDING } from "../types/SortTypes";

export function fetchCustomerDomains(limit, page, sort, filters) {
  return axios.get(CUSTOMER_DOMAINS_API_ENDPOINT, {
    params: {
      limit: limit ? limit : 10,
      page: page ? page : 1,
      sort_by: sort ? sort.sort_by : "expiry_date",
      order_by: sort ? sort.order_by : SORT_ORDER_DESCENDING,
      ...filters
    }
  });
}

export function requestRenewDomain(domain) {
  return axios.put(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_REQUEST_RENEWAL_API_ENDPOINT, domain)
  );
}

export function requestRenewDomainWithPeriod(domain, period) {
  return axios.put(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_REQUEST_RENEWAL_API_ENDPOINT, domain),
    {
      renewal_period: period
    }
  );
}

export function fetchCustomerDomainsWithoutHosting() {
  return axios.get(CUSTOMER_DOMAINS_WITHOUT_HOSTING_API_ENDPOINT);
}

export function fetchCustomerPersonalInformation() {
  return axios.get(CUSTOMER_PERSONAL_INFORMATION_API_ENDPOINT);
}

export function fetchCustomerDomainsFilterStatusCollection() {
  return axios.get(CUSTOMER_DOMAINS_FILTER_STATUS_COLLECTION_API_ENDPOINT);
}

export function fetchCustomerPayersForFilter() {
  return axios.get(CUSTOMER_PAYERS_FOR_FILTER_API_ENDPOINT);
}

export function fetchCustomerDomainsFilterExtensionsCollection() {
  return axios.get(CUSTOMER_DOMAINS_FILTER_EXTENSIONS_COLLECTION_API_ENDPOINT);
}

export function fetchCustomerDomainsFilterRegisterContactsCollection() {
  return axios.get(
    CUSTOMER_DOMAINS_FILTER_REGISTER_CONTACTS_COLLECTION_API_ENDPOINT
  );
}

export function fetchCustomerProductsFilterStatusCollection() {
  return axios.get(CUSTOMER_PRODUCTS_FILTER_STATUS_COLLECTION_API_ENDPOINT);
}

export function fetchCustomerProductsFilterProductsCollection() {
  return axios.get(CUSTOMER_PRODUCTS_FILTER_PRODUCTS_COLLECTION_API_ENDPOINT);
}

export function fetchCustomerProducts(limit, page, sort, filters) {
  return axios.get(CUSTOMER_PRODUCTS_API_ENDPOINT, {
    params: {
      limit: limit ? limit : 10,
      page: page ? page : 1,
      sort_by: sort ? sort.sort_by : "renewal_date",
      order_by: sort ? sort.order_by : SORT_ORDER_DESCENDING,
      ...filters
    }
  });
}

export function fetchCustomerServers(limit, page, sort, filters) {
  return axios.get(CUSTOMER_SERVERS_API_ENDPOINT, {
    params: {
      limit: limit ? limit : 10,
      page: page ? page : 1,
      sort_by: sort ? sort.sort_by : "renewal_date",
      order_by: sort ? sort.order_by : SORT_ORDER_DESCENDING,
      ...filters
    }
  });
}

export function fetchCustomerServersFilterStatusCollection() {
  return axios.get(CUSTOMER_SERVERS_FILTER_STATUS_COLLECTION_API_ENDPOINT);
}

export function fetchCustomerServersFilterServersCollection() {
  return axios.get(CUSTOMER_SERVERS_FILTER_PRODUCTS_COLLECTION_API_ENDPOINT);
}

export function fetchCustomerProductDetails(productId) {
  return axios.get(
    sprintf(CUSTOMER_PRODUCTS_PRODUCT_DETAILS, { id: productId })
  );
}

export function fetchCustomerServerDetails(productId) {
  return axios.get(
    sprintf(CUSTOMER_PRODUCTS_SERVER_DETAILS, { id: productId })
  );
}

export function getHostingCPanelDirectLoginLink(hosting) {
  return axios.get(
    sprintf(
      CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_API_ENDPOINT,
      hosting
    )
  );
}

export function changeHostingCPanelPassword(
  hosting,
  newPassword,
  confirmNewPassword
) {
  return axios.put(
    sprintf(
      CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_CHANGE_PASSWORD_API_ENDPOINT,
      hosting
    ),
    {
      password: newPassword,
      confirm_password: confirmNewPassword
    }
  );
}

export function cancelDomain(domain, period) {
  return axios.put(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_CANCEL_API_ENDPOINT, domain),
    {
      type: period
    }
  );
}

export function autoRenewDomain(domain) {
  return axios.put(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_AUTORENEW_API_ENDPOINT, domain)
  );
}

export function fetchDomain(id) {
  return axios.get(sprintf(CUSTOMER_DOMAINS_DOMAIN_API_ENDPOINT, { id: id }));
}

export function transferInDomain(domain, epp_code) {
  return axios.post(CUSTOMER_DOMAINS_DOMAIN_TRANSFER_IN_API_ENDPOINT, {
    domain,
    epp_code
  });
}

export function fetchDomainByName(domainName) {
  return axios.get(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_BY_NAME_API_ENDPOINT, { name: domainName })
  );
}

export function fetchDomainNameServers(id) {
  return axios.get(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_NAME_SERVERS_API_ENDPOINT, { id: id })
  );
}

export function fetchDomainRegisterContacts(id) {
  return axios.get(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_REGISTER_CONTACTS_API_ENDPOINT, { id: id })
  );
}

export function fetchEmail(id) {
  return axios.get(sprintf(CUSTOMER_EMAILS_EMAIL_API_ENDPOINT, { id: id }));
}

export function unlockDomain(domain) {
  return axios.put(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_UNLOCK_API_ENDPOINT, domain)
  );
}

export function handleDomainRequest(data) {
  return axios.post(CUSTOMER_DOMAINS_CREATE_OPERATION, {
    ...data,
  });
}

export function updateDomainRequest(data) {
  // console.log(data)
  return axios.post(CUSTOMER_DOMAINS_UPDATE_OPERATION, {
    ...data,
  });
}

export function lockDomain(domain) {
  return axios.put(sprintf(CUSTOMER_DOMAINS_DOMAIN_LOCK_API_ENDPOINT, domain));
}

export function fetchDomainLockStatus(domain) {
  return axios.get(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_LOCKED_STATUS_API_ENDPOINT, domain)
  );
}

export function fetchDomainServices(domain) {
  return axios.get(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_SERVICES_API_ENDPOINT, domain)
  );
}

export function fetchProductServices(product) {
  return axios.get(
    sprintf(CUSTOMER_PRODUCTS_PRODUCT_SERVICES_API_ENDPOINT, product)
  );
}

export function fetchHostingBackups(product) {
  return axios.get(
    sprintf(CUSTOMER_PRODUCTS_PRODUCT_HOSTING_BACKUP_API_ENDPOINT, product)
  );
}
export function fetchHostingBackupHistory(product, limit, page) {
  return axios.get(
    sprintf(CUSTOMER_PRODUCTS_PRODUCT_HOSTING_BACKUP_HISTORY_API_ENDPOINT, product), {
    params: {
      limit: limit ? limit : 10,
      page: page ? page : 1
    }
  }
  );
}

export function startRestore(product, restoreOptions) {
  return axios.post(
    sprintf(CUSTOMER_PRODUCTS_PRODUCT_HOSTING_BACKUP_RESTORE_API_ENDPOINT, product), {
    ...restoreOptions
  }
  );
}

export function fetchServerServices(product) {
  return axios.get(
    sprintf(CUSTOMER_PRODUCTS_SERVER_SERVICES_API_ENDPOINT, product)
  );
}

export function cancelDomainService(domain, service) {
  return axios.put(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_API_ENDPOINT, {
      domain,
      service
    })
  );
}

export function buyDomainIdProtection(domain) {
  return axios.post(CUSTOMER_DOMAINS_DOMAIN_BUY_ID_PROTECTION, {
    domain
  });
}

export function cancelProductService(product, service) {
  return axios.put(
    sprintf(CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_API_ENDPOINT, {
      product,
      service
    })
  );
}

export function fetchEppTransferCode(domain) {
  return axios.get(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_GET_EPP_TRANSFER_CODE_API_ENDPOINT, domain)
  );
}

export function domainDirectTransfer(domain, epp_code) { }

export function updateDomainNameservers(domain, nameservers) {
  return axios.put(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_NAMESERVERS_UPDATE, domain),
    {
      name_servers: {
        ...nameservers
      }
    }
  );
}

export function fetchCustomerContacts() {
  return axios.get(CUSTOMER_CONTACTS_API_ENDPOINT);
}

export function fetchCustomerContact(contact) {
  return axios.get(sprintf(CUSTOMER_CONTACTS_CONTACT_DETAILS, contact));
}

export function changeCustomerDomainContact(domain, contactType, contact) {
  return axios.put(
    sprintf(CUSTOMER_CONTACTS_CHANGE_DOMAIN_CONTACT, { type: contactType }),
    {
      domain_id: domain.id,
      ...contact
    }
  );
}

export function assignCustomerContactToProduct(
  product,
  productType,
  contact,
  role
) {
  return axios.post(
    sprintf(CUSTOMER_CONTACTS_ASSIGN_PRODUCT_CONTACT, { type: role }),
    {
      product_id: product.item.id,
      type: productType,
      name: contact.contact.name,
      email: contact.contact.email
    }
  );
}

export function aggregatedCallToAssignContactRoleForProduct(apiCallsArray) {
  return axios.all(apiCallsArray);
}

export function unassignProductFromContact(product, contact) {
  return axios.post(
    CUSTOMER_CONTACTS_UNASSIGN_PRODUCT_FROM_CONTACT_API_ENDPOINT,
    {
      contact_item_id: product.id
    }
  );
}

export function acceptInvitation(token) {
  return axios.post(sprintf(CUSTOMER_CONTACTS_ACCEPT_INVITATION, token));
}

export function registerAssistant(token, assistant) {
  return axios.put(CUSTOMER_CONTACTS_REGISTER_ASSISTANT, {
    token: token,
    ...assistant
  });
}

export function searchContacts(keyword) {
  return axios.get(SEARCH_CONTACTS_API_ENDPOINT, {
    params: { keyword }
  });
}

export function searchPayers(keyword) {
  return axios.get(SEARCH_PAYERS_API_ENDPOINT, {
    params: { keyword }
  });
}

export function updateCustomerPersonalInfromation(personalInformation) {
  return axios.put(
    CUSTOMER_PERSONAL_INFORMATION_API_ENDPOINT,
    personalInformation
  );
}

export function updateCustomerLanguage(language) {
  return axios.put(CUSTOMER_UPDATE_LANGUAGE_API_ENDPOINT, {
    language: language
  });
}

export function fetchCustomerEmails(limit, page, sort, filters) {
  return axios.get(CUSTOMER_EMAILS_API_ENDPOINT, {
    params: {
      limit: limit ? limit : 10,
      page: page ? page : 1,
      sort_by: sort ? sort.sort_by : "date",
      order_by: sort ? sort.order_by : SORT_ORDER_DESCENDING,
      ...filters
    }
  });
}

export function fetchCustomerDomainZoneFile(domain) {
  return axios.get(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_ZONE_FILE_API_ENDPOINT, { id: domain.id })
  );
}

export function createCustomerDomainZoneFile(domain) {
  return axios.post(
    sprintf(CUSTOMER_DOMAINS_CREATE_DOMAIN_ZONE_FILE_API_ENDPOINT, {
      id: domain.id
    })
  );
}

export function deleteCustomerDomainZoneFile(domain) {
  return axios.delete(
    sprintf(CUSTOMER_DOMAINS_DELETE_DOMAIN_ZONE_FILE_API_ENDPOINT, {
      id: domain.id
    })
  );
}

export function resetCustomerDomainZoneFile(domain) {
  return axios.put(
    sprintf(CUSTOMER_DOMAINS_RESET_DOMAIN_ZONE_FILE_API_ENDPOINT, {
      id: domain.id
    })
  );
}

export function addCustomerDomainZoneRecord(domain, record) {
  return axios.post(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_ZONE_ADD_RECORD_API_ENDPOINT, {
      id: domain.id
    }),
    record
  );
}

export function saveCustomerDomainZoneRecord(domain, record) {
  return axios.put(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_ZONE_UPDATE_RECORD_API_ENDPOINT, {
      id: domain.id
    }),
    record
  );
}

export function deleteCustomerDomainZoneRecord(domain, record) {
  return axios.delete(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_ZONE_DELETE_RECORD_API_ENDPOINT, {
      id: domain.id
    }),
    { data: { ...record } }
  );
}

export function checkCustomerDomainRedirection(domain) {
  return axios.get(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_REDIRECT_AVAILABLE_API_ENDPOINT, {
      id: domain.id
    })
  );
}

export function getCustomerDomainRedirection(domain) {
  return axios.get(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_GET_REDIRECTION_API_ENDPOINT, {
      id: domain.id
    })
  );
}

export function updateCustomerDomainRedirection(domain, redirection) {
  return axios.put(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_REDIRECTION_UPDATE_API_ENDPOINT, {
      id: domain.id
    }),
    {
      redirect_to: redirection
    }
  );
}

export function deleteCustomerDomainRedirection(domain) {
  return axios.delete(
    sprintf(CUSTOMER_DOMAINS_DOMAIN_REDIRECTION_DELETE_API_ENDPOINT, {
      id: domain.id
    })
  );
}

// Domain Templates
export function createNewDomainTemplate(template) {
  return axios.post(
    CUSTOMER_CREATE_DOMAIN_TEMPLATE,
    template
  );
}

export function getDomainTemplate(id) {
  return axios.get(
    sprintf(CUSTOMER_GET_DOMAIN_TEMPLATE, {
      id: id
    })
  );
}
export function getListDomainTemplates({ page = 1, limit = 10, sort_by, order_by } = {}) {
  return axios.get(CUSTOMER_GET_LIST_DOMAIN_TEMPLATES, {
    params: {
      page,
      limit,
      ...(sort_by && { sort_by }),
      ...(order_by && { order_by }),
    },
  });
}

// END

// Document Managment
export function downloadDocumnetForSign() {
  return axios.get(
    CUSTOMER_DOWNLOAD_DOCUMENT
  );
}
export function uploadDocumentSigned(formData) {
  return axios.post(CUSTOMER_UPLOAD_DOCUMENT, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function uploadDocumentSignedV2(formData) {
  return axios.post(CUSTOMER_UPLOAD_DOCUMENT_V2, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// END

// Attach template to domain
export function assignTemplateToDomain(
  template_id,
  item_id,
  cart,
  ns1 = "",
  ns2 = "",
  ns3 = "",
  ns4 = "",
  ns5 = "",
  ip1 = "",
  ip2 = "",
  ip3 = "",
  ip4 = "",
  ip5 = ""
) {
  return axios.post(sprintf(CUSTOMER_DOMAINS_TEMPLATES_ASSIGN, {
    cart: cart
  }), {
    template_id,
    item_id,
    ns1,
    ns2,
    ns3,
    ns4,
    ns5,
    ip1,
    ip2,
    ip3,
    ip4,
    ip5
  });
}

// END
// Assign delivery type for order item (domain)

export function assignItemDeliveryType(items, cart) {
  return axios.post(sprintf(CUSTOMER_ASSIGN_ITEM_DELIVERY_TYPE, { cart: cart }), {
    items: items,
  });
}

export function fetchDataFromUJP(tax_number) {
  return axios.get(sprintf(CUSTOMER_FETCH_DATA_FROM_UJP, {
    tax_number: tax_number
  })
  );
}

export function getDomainTemplateById(item_id, cart) {
  return axios.get(sprintf(CUSTOMER_GET_TEMPLATE_BY_ID, {
    item_id: item_id,
    cart: cart
  })
  );
}

export function signDocumentEvrotrust(payload) {
  return axios.post(CUSTOMER_SIGN_DOCUMENT_EVROTRUST, payload);
}

export function checkDocumentSignedEvrotrust(payload) {
  return axios.post(CUSTOMER_CHECK_DOCUMENT_EVROTRUST, payload);
}

export function checkIfTemplateExists(id) {
  return axios.get(sprintf(CUSTOMER_TEMPLATE_ADD_TEKOVNA, {
    id: id,
  })
  );
}
// END
export function deleteTemplateById(id) {
  return axios.delete(
    sprintf(CUSTOMER_TEMPLATE_DELETE_TEMPLATE, {
      id: id
    })
  );
}

export function updateTemplateById(id, payload) {
  return axios.put(
    sprintf(CUSTOMER_TEMPLATE_UPDATE_TEMPLATE, {
      id: id
    }),
    payload
  );
}

export function attachCompanyCertificate(payload) {
  return axios.post(CUSTOMER_ATTACH_COMPANY_CERTIFICATE, payload);
}