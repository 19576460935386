import React from 'react';
import styled from 'styled-components';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { theme } from '../../design/Themes';
import icon_warning from "../../assets/images/icon-warning.png";

const Wrapper = styled.div`
  background-color: ${({ type }) => (type === 'warning' ? '#E0F2FE' : theme.blue5)};
  border-left: 4px solid ${({ type }) => (type === 'warning' ? '#0284C7' : '#2563EB')};
  padding: 30px 150px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.white};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledText = styled.div`
  font-size: 17px;
  font-weight: 100;
  color: ${theme.white};
`;

const StyledTextSpan = styled.span`
  font-size: 20px;
  font-weight: 900;
  color: ${theme.white};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CalloutBox = ({ type, bold, message, actionLabel, onAction, icon = "mdi:alert-circle" }) => {
  return (
    <Wrapper type={type}>
      <ContentWrapper>
        <IconWrapper>
          <img src={icon_warning} alt="" className="mr-2" />
        </IconWrapper>
        <StyledText>
          <StyledTextSpan>{bold}</StyledTextSpan> {message}
        </StyledText>
      </ContentWrapper>
      {actionLabel && (
        <PrimaryButton onClick={onAction}>
          {actionLabel}
        </PrimaryButton>
      )}
    </Wrapper>
  );
};

export default CalloutBox;
