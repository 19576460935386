import React from 'react';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';
import { theme } from '../../design/Themes';
import InputFieldIcon from '../InputField/InputFieldIcon';
// Import default English labels
import enLabels from 'react-phone-number-input/locale/en.json';

const PhoneInputWrapper = styled.div`
  margin-bottom: 15px;
  position: relative;
  
  .PhoneInput {
    border: 1px solid ${theme.neutral3};
    border-radius: 4px;
    padding: 2px 8px;
    background: white;
    
    &:focus-within {
      border-color: ${theme.blue5};
      box-shadow: 0 0 0 1px ${theme.blue5};
    }
  }
  
  .PhoneInputInput {
    border: none;
    outline: none;
    font-size: 14px;
    height: 34px;
    width: 100%;
    color: ${theme.neutralBase};
    
    &:disabled {
      color: ${theme.neutral6};
    }
  }
  
  .PhoneInputCountry {
    margin-right: 8px;
  }
  
  &.is-invalid .PhoneInput {
    background-color: ${theme.red1};
    border: 1px solid ${theme.redBase};
  }
  
  &.is-valid .PhoneInput {
    background-color: #e6ffe6;
    border: 1px solid ${theme.greenBase};
  }
  
  label {
    font-size: 14px;
    color: ${theme.neutralBase};
    margin-bottom: 5px;
    display: block;
  }
  
  .error-text {
    color: ${theme.red2};
    font-size: 12px;
    margin-top: 4px;
    display: block;
  }
`;

const PhoneNumberInput = ({
  name,
  label,
  placeholder,
  defaultCountry,
  required = true,
  translate,
  disabled = false,
}) => {

  const customLabels = {
    ...enLabels,
    MK: 'Macedonia'
  };

  const isMacedonianNumber = (value) => {
    return value && value.startsWith('+389');
  };

  return (
    <Field
      name={name}
      validate={(value) => {
        if (required && !value) {
          return translate("validation.enter-phone-number");
        }
        if (value && !isValidPhoneNumber(value) && !isMacedonianNumber(value)) {
          return translate("validation.enter-phone-number");
        }
        if (value && !isValidPhoneNumber(value) && isMacedonianNumber(value)) {
          return translate("validation.invalid-phone-number");
        }
        return undefined;
      }}
      render={({ input, meta }) => {
        let fieldValidationClass = "";
        if (meta.touched && !meta.pristine && meta.valid && !meta.invalid) {
          fieldValidationClass = "is-valid";
        }
        if (
          (meta.touched && !meta.valid && meta.invalid) ||
          (meta.invalid && meta.initial)
        ) {
          fieldValidationClass = "is-invalid";
        }

        return (
          <PhoneInputWrapper className={fieldValidationClass}>
            <label>
              {label} {required && <span style={{ color: 'red' }}>*</span>}
            </label>
            <PhoneInput
              {...input}
              international
              defaultCountry={defaultCountry}
              placeholder={placeholder}
              countryCallingCodeEditable={false}
              smartCaret
              limitMaxLength
              disabled={disabled}
              labels={customLabels} // Use the extended labels
            />
            {meta.touched && !meta.pristine && meta.valid && !meta.invalid && (
              <InputFieldIcon
                icon="success"
                valid={meta.valid}
                invalid={meta.invalid}
                style={{ position: 'absolute', right: '8px', top: '46px' }}
              />
            )}
            {((meta.touched && meta.invalid && !meta.valid) ||
              (meta.invalid && meta.initial)) && (
                <span className="error-text">{meta.error}</span>
              )}
          </PhoneInputWrapper>
        );
      }}
    />
  );
};

export default PhoneNumberInput;