import styled from "styled-components";
import Button from "../Button";
import { theme } from "../../../design/Themes";

const LinkButton = styled(Button)`
  background-color: transparent;
  border: none;
  padding: 0;
  color: ${theme.blueBase};
  text-decoration: underline;
  font-weight: normal;

  &:hover {
    color: ${theme.blue4};
    text-decoration: underline;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${theme.blue3};
  }

  &:active {
    color: ${theme.blue5};
  }

  &:disabled {
    color: ${theme.neutral3};
    text-decoration: none;
    cursor: not-allowed;
  }
`;

LinkButton.defaultProps = {
    className: "btn link-button"
};

export default LinkButton;
