import React, { useEffect, useState } from 'react'
import styled, { ThemeConsumer } from "styled-components";
import { withLocalize, Translate } from "react-localize-redux";
import { isMobile, isMobileOnly } from "react-device-detect";
import { theme } from "../../../../design/Themes";
import Modal from '../../../../components/Modal';
import en from "../../../../translations/en.json";
import mk from "../../../../translations/mk.json";
import { getApplicationLocale } from "../../../../services/localizationService";
import { CONTACT_DETAILS_TYPE_COMPANY, CONTACT_DETAILS_TYPE_INDIVIDUAL } from "../../../Domains/ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes";
import { validateCountry, validateEmail, validateFirstName, validateLastName, validateMkPhoneNumber } from "../../../../common/validationRules";
import { Field, Form } from 'react-final-form';
import BlockRadioboxNew from '../../../../components/BlockRadioboxNew';
import iconPersonInactive from "../../../../assets/images/icon-person-inactive.svg";
import iconBusinessActive from "../../../../assets/images/icon-business-active.svg";
import iconBusinessInactive from "../../../../assets/images/icon-business-inactive.svg";
import InputField from '../../../../components/InputField';
import isEmpty from "lodash/isEmpty";
import { transliterateToCyrilic } from "../../../../services/transliterateService";
import { fetchCustomerPersonalInformation, fetchDataFromUJP, uploadDocumentSignedV2 } from '../../../../services/customerService';
import SelectBox from '../../../../components/SelectBox';
import Icon from '../../../../components/Icon';
import CustomTooltip from '../../../../components/CustomTooltip';
import SecondaryButton from '../../../../components/Buttons/SecondaryButton';
import Radiobox from '../../../../components/Radiobox';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import iconUpload from "../../../../assets/images/icon-upload-active.svg";
import { withRouter } from 'react-router-dom';
import { getCountriesFormatted } from '../../../Addressing/reducers/addressingReducer';
import { connect } from 'react-redux';
import PhoneNumberInput from '../../../../components/PhoneNumberInput/PhoneNumberInput';
import BackupRestoreHistory from '../../../Hosting/ViewHosting/BackupRestore/BackupRestoreHistory';

// Lang implementation
const lang = {
    en: en,
    mk: mk
};

const resolvePath = (object, path, defaultValue) =>
    path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

const translate = key =>
    resolvePath(lang[getApplicationLocale()], key, undefined);


const StyledDescription = styled.div`
  font-size: ${isMobileOnly ? '12px' : '14px;'}
  color: ${theme.neutral6};
  margin-bottom: ${isMobileOnly ? '10px' : '20px'};
`;

const StyledHeaderDescription = styled.div`
    font-size: ${isMobileOnly ? '12px' : '12px;'}
  color: ${theme.neutral6};
  font-weight: 300;
  margin-top: ${isMobileOnly ? '5px' : '10px'};
  margin-bottom: ${isMobileOnly ? '10px' : '20px'};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledBorder = styled.div`
  border: 1px solid ${theme.neutral3}
`;

const StyledErrorText = styled.div`
  font-size: 14px;
  color: ${theme.red2};
  display: flex;
  align-items: center;
`;

const StyledSmallText = styled.div`
  font-size: 14px;
  color: ${theme.yellowBase};
  line-height: 1.2;
  display: flex;
  font-weight: 400;
  align-items: center;
`;


const StyledFooter = styled.div`
  gap: ${isMobileOnly ? '10px' : '20px'}
  padding-bottom: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;




const TabContainer = styled.div`
  margin-top: 20px;
`;

const TabButtons = styled.div`
  display: flex;
  gap: ${isMobileOnly ? '5px' : '15px'}
`;

const TabButton = styled.button`
  background-color: ${props => (props.active ? theme.blue5 : theme.neutral2)};
  border: none;
  padding: ${isMobileOnly ? '' : '10px 25px;'}
  border-radius: 4px 4px 0 0;
  font-size: 14px;
  font-weight: bold;
  color: ${theme.white};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => (props.disabled ? theme.neutral2 : theme.blue5)};
  }
`;

const TabContent = styled.div`
  font-size: 14px;
  color: ${theme.neutral6};
`;

const StyledContactTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.43;
  color: ${theme.neutralBase};
  text-transform: uppercase;
  float: left;
`;

const StyledContactHeader = styled.div`
  margin-bottom: 20px;
`;
const StyledCustomTooltip = styled.div`
  display: inline;
  margin-bottom: 5px
`;

const StyledCyrillic = styled.div`
  min-width: 250px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 0;
`;


const StyledHelp = styled.div`
  min-width: 250px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 30px;
`;

const StyledTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${theme.neutralBase};
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledUnderTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.neutralBase};
  padding-top: 10px;
  padding-bottom: 10px;
`;

const ManageDomainContact = ({ showContactInfoModal, setShowContactInfoModal, setShowDataPreview, formData, setFormData, countries, isEdit = false }) => {
    const [selectedCountry, setSelectedCountry] = useState('');
    const [disabledFields, setDisabledField] = useState('');
    const [restrictionMessageCheck, setRestrictionMessageCheck] = useState(false);
    const [shouldValidateMacedonianAdmin, setShouldValidateMacedonianAdmin] = useState(false);
    const [shouldValidateMacedonianTechnical, setShouldValidateMacedonianTechnical] = useState(false);
    const [shouldValidateMacedonian, setShouldValidateMacedonian] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [maxUnlockedTab, setMaxUnlockedTab] = useState(1);
    const [selectedAdministrativeCountry, setSelectedAdministrativeCountry] = useState('');
    const [selectedTechnicalCountry, setSelectedTechnicalCountry] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeTab, setActiveTab] = useState("option1");

    useEffect(() => {
        if (isEdit) {
            setActiveTab("option1");
            setMaxUnlockedTab(4);
        }

    }, []);

    const getPrimaryButtonLabel = () => {
        return activeTab === "option4"
            ? translate("cart.wizard.account-info-modal.view")
            : translate("cart.wizard.account-info-modal.save-and-continue");
    };

    const handleModalPrimaryClick = async () => {
        if (activeTab === "option1") {
            document.getElementById("domain-contact-details-form").dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }
        else if (activeTab === "option2") {
            document.getElementById("contact-details-form").dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }
    };

    const handleFormSubmit = (values, form) => {
        console.log("Submitting values:", values);

        // **First, clear fields based on `contact_type` immediately**
        if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) {
            form.change("first_name", "");
            form.change("last_name", "");
        } else {
            form.change("company", "");
            form.change("tax_number", "");
        }

        // **Now, update state**
        setFormData((prevData) => ({
            ...prevData,
            option1: {
                ...prevData.option1,
                contact_type: values.contact_type,
                first_name:
                    values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
                        ? values.first_name || prevData.option1.first_name || ""
                        : "",
                last_name:
                    values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
                        ? values.last_name || prevData.option1.last_name || ""
                        : "",
                company:
                    values.contact_type === CONTACT_DETAILS_TYPE_COMPANY
                        ? values.company || prevData.option1.company || ""
                        : "",
                tax_number:
                    values.contact_type === CONTACT_DETAILS_TYPE_COMPANY
                        ? values.tax_number || prevData.option1.tax_number || ""
                        : "",
                email: values.email || prevData.option1.email || "",
                phone: values.phone || prevData.option1.phone || "",
                address: values.address1 || prevData.option1.address || "",
                street_number: values.street_number || prevData.option1.street_number || "",
                city: values.city || prevData.option1.city || "",
                zip: values.zip || prevData.option1.zip || "",
                country: values.country || prevData.option1.country || "",
            },
        }));


        setActiveTab("option2");
    };


    const handleContactSubmit = (values) => {
        console.log("Updating formData with values:", values);
        setFormData((prevData) => ({
            ...prevData,
            option2: {
                administrative_contact: {
                    name: values.administrative_contact && values.administrative_contact.name ? values.administrative_contact.name : "",
                    surname: values.administrative_contact && values.administrative_contact.surname ? values.administrative_contact.surname : "",
                    email: values.administrative_contact && values.administrative_contact.email ? values.administrative_contact.email : "",
                    phone: values.administrative_contact && values.administrative_contact.phone ? values.administrative_contact.phone : "",
                    country: values.administrative_contact && values.administrative_contact.country ? values.administrative_contact.country : "",
                },
                technical_contact: {
                    name: values.technical_contact && values.technical_contact.name ? values.technical_contact.name : "",
                    surname: values.technical_contact && values.technical_contact.surname ? values.technical_contact.surname : "",
                    email: values.technical_contact && values.technical_contact.email ? values.technical_contact.email : "",
                    phone: values.technical_contact && values.technical_contact.phone ? values.technical_contact.phone : "",
                    country: values.technical_contact && values.technical_contact.country ? values.technical_contact.country : "",
                },
            },
        }));
    };

    const handleCountryChange = (selectedOption, form) => {
        // console.log(selectedOption)
        form.change("country", selectedOption);
        setShouldValidateMacedonian(selectedOption.label === "Macedonia");
    };

    const validateTaxNumber = (value, values = {}) => {
        if (values.contact_type === "company") {
            if (!value || isEmpty(value)) {
                return translate("validation.enter-tax-number");
            }
            if (!/^\d+$/.test(value)) {
                return translate("validation.tax-number-numeric");
            }
        }
        return undefined; // No error if not required
    };

    const validateContactDetails = (values) => {
        const errors = {};

        if (values.administrative_contact) {
            errors.administrative_contact = {
                name: validateFirstName(values.administrative_contact.name),
                surname: validateLastName(values.administrative_contact.surname),
                email: validateEmail(values.administrative_contact.email),
                phone: validateMkPhoneNumber(values.administrative_contact.phone),
                country: values.administrative_contact.country && values.administrative_contact.country.value
                    ? validateCountry(values.administrative_contact.country.value)
                    : translate("cart.wizard.account-info-modal.validation.enter-country")
            };
        }

        if (values.technical_contact) {
            errors.technical_contact = {
                name: validateFirstName(values.technical_contact.name),
                surname: validateLastName(values.technical_contact.surname),
                email: validateEmail(values.technical_contact.email),
                phone: validateMkPhoneNumber(values.technical_contact.phone),
                country: values.technical_contact.country && values.technical_contact.country.value
                    ? validateCountry(values.technical_contact.country.value)
                    : translate("cart.wizard.account-info-modal.validation.enter-country") // This ensures the error appears if country is missing
            };
        }

        return errors;
    };

    const validateAccountDetails = (values = {}) => {
        const errors = {};

        // Validate email, phone, and country (always required)
        errors.email = values.email ? validateEmail(values.email) : translate("validation.enter-email");
        // errors.phone = values.phone ? validateMkPhoneNumber(values.phone) : translate("validation.enter-phone-number");
        errors.country = values.country && values.country.value
            ? validateCountry(values.country.value)
            : translate("cart.wizard.account-info-modal.validation.enter-country");

        // Check if `contact_type` is set
        if (!values.contact_type) {
            errors.contact_type = translate("cart.wizard.account-info-modal.validation.select-entity-type");
        }
        else if (values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL) {
            // Individual must have first_name & last_name
            errors.first_name = values.first_name ? validateFirstName(values.first_name) : translate("validation.enter-first-name");
            errors.last_name = values.last_name ? validateLastName(values.last_name) : translate("validation.enter-last-name");
        }
        else if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) {
            // Company must have tax_number & company name
            errors.tax_number = values.tax_number ? validateTaxNumber(values.tax_number) : translate("cart.wizard.account-info-modal.validation.enter-tax-number");
            errors.company = values.company ? undefined : translate("validation.enter-company");
        }

        return errors;
    };

    const capitalizeFirstLetter = (text) => {
        if (!text) return "";

        // Split into words and spaces, process words, then rejoin
        return text
            .split(/(\s+)/) // Split into words AND spaces (keeps all whitespace)
            .map((token) => {
                // Only capitalize tokens that are NOT whitespace
                if (token.trim() === "") return token; // Leave spaces unchanged
                return token.charAt(0).toUpperCase() + token.slice(1);
            })
            .join(""); // Rejoin tokens (spaces preserved)
    };

    const handleTaxNumberChange = async (event, form) => {
        const value = event.target.value;
        form.change("tax_number", value);
        if (value.length === 13) {
            setIsFetching(true);
            try {
                console.log(value)
                const response = await fetchDataFromUJP(value);
                if (response.error) {
                    console.log("Error fetching company data: ", response.error);
                } else {
                    console.log(response)
                    const { name, address, city, phone } = response.data.data;
                    form.change("company", name || "");
                    form.change("address1", address || "");
                    form.change("city", city || "");
                    form.change("phone", phone || "");
                }
            } catch (error) {
                console.log("Error fetching company data: ", error);
            } finally {
                setIsFetching(false);
            }
        }
    };

    const handleCountryChangeAdministrative = (selectedOption) => {
        setSelectedAdministrativeCountry(selectedOption);
        setShouldValidateMacedonianAdmin(selectedOption.label === "Macedonia");
    };

    const handleCountryChangeTechnical = (selectedOption) => {
        setSelectedTechnicalCountry(selectedOption);
        setShouldValidateMacedonianTechnical(selectedOption.label === "Macedonia");
    };

    const closeContactInfoModal = () => {
        setShowContactInfoModal(false);
        setActiveTab("option1");
        setFormData(prevData => ({
            ...prevData,
            option1: {
                contact_type: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                company: "",
                tax_number: "",
                street_number: "",
                address: "",
                city: "",
                zip: "",
                country: "",
            },
        }));
        // setSelectedProduct(null);
    };


    return (
        <Modal
            isOpen={showContactInfoModal}
            onCloseModal={() => closeContactInfoModal()}
            title={
                isEdit
                    ? translate("domain-templates.edit-template-title") + formData.template_name
                    : translate("cart.wizard.account-info-modal.domain-registration-information")
            } size="xxl"
            // Pass in a custom header that renders the tab buttons
            tabButtons={true}
            underHeader={() => (
                <>
                    {!isEdit ? (
                        <StyledHeaderDescription>
                            {translate("domain-templates.description-in-add-modal")}
                        </StyledHeaderDescription>
                    ) : (
                        <StyledHeaderDescription>
                            {translate("domain-templates.description-in-edit-modal")}
                        </StyledHeaderDescription>
                    )}
                    <TabContainer>
                        <TabButtons>
                            <TabButton
                                active={activeTab === "option1"}
                                disabled={false}
                                onClick={() => setActiveTab("option1")}
                            >
                                {translate("cart.wizard.account-info-modal.registrant")}
                            </TabButton>
                            <TabButton
                                active={activeTab === "option2"}
                                disabled={maxUnlockedTab < 2}
                                onClick={() => maxUnlockedTab >= 2 && setActiveTab("option2")}
                            >
                                {translate("cart.wizard.account-info-modal.contact-data")}
                            </TabButton>
                        </TabButtons>
                    </TabContainer>
                </>
            )}
            body={() => (
                <>
                    {activeTab === "option1" && (

                        <TabContainer>
                            <TabContent>

                                <StyledDescription>
                                    {translate("cart.wizard.account-info-modal.entity-type-for-domain-registration")}
                                </StyledDescription>
                                <Form
                                    onSubmit={handleFormSubmit}
                                    validate={validateAccountDetails}
                                    initialValues={{
                                        country: formData.option1.country || selectedCountry,
                                        contact_type: formData.option1.contact_type || CONTACT_DETAILS_TYPE_INDIVIDUAL,
                                        first_name: formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
                                            ? formData.option1.first_name || ""
                                            : "", // Reset if not Individual
                                        last_name: formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
                                            ? formData.option1.last_name || ""
                                            : "", // Reset if not Individual
                                        email: formData.option1.email || "",
                                        phone: formData.option1.phone || "",
                                        company: formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY
                                            ? formData.option1.company || ""
                                            : "", // Reset if not Company
                                        tax_number: formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY
                                            ? formData.option1.tax_number || ""
                                            : "", // Reset if not Company
                                        address1: formData.option1.address || "",
                                        street_number: formData.option1.street_number || "",
                                        city: formData.option1.city || "",
                                        zip: formData.option1.zip || "",
                                    }}
                                    render={({ handleSubmit, values, submitting, invalid, form }) => {

                                        const handleResetField = (fieldName1, fieldName2) => {
                                            form.change(fieldName1, "");
                                            form.change(fieldName2, "");
                                        };
                                        // const restrictionMessage = getRestrictionMessage(values.contact_type, values.country && values.country.label);
                                        return (
                                            <form
                                                id="domain-contact-details-form"
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="row">
                                                    <div className="col-xl-12 col-sm-12 col-md-12">

                                                        <div className="row mb-2">
                                                            <div className="col-xl-3 col-md-6 mb-2" onClick={() => handleResetField(CONTACT_DETAILS_TYPE_COMPANY, "tax_number")}>
                                                                <Field
                                                                    component={BlockRadioboxNew}
                                                                    activeIcon={iconPersonInactive}
                                                                    defaultIcon={iconPersonInactive}
                                                                    name="contact_type"
                                                                    value={CONTACT_DETAILS_TYPE_INDIVIDUAL}
                                                                    type="radio"
                                                                    defaultValue={CONTACT_DETAILS_TYPE_INDIVIDUAL}
                                                                >
                                                                    {translate("cart.wizard.individual")}
                                                                </Field>
                                                            </div>
                                                            <div className="col-xl-3 col-md-6">
                                                                <Field
                                                                    component={BlockRadioboxNew}
                                                                    activeIcon={iconBusinessActive}
                                                                    defaultIcon={iconBusinessInactive}
                                                                    name="contact_type"
                                                                    value={CONTACT_DETAILS_TYPE_COMPANY}
                                                                    type="radio"
                                                                >
                                                                    {translate("cart.wizard.company")}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        {values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL && (
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6 col-md-6">
                                                                    <Field
                                                                        component={InputField}
                                                                        label={translate("cart.wizard.first-name")}
                                                                        name="first_name"
                                                                        readOnly={disabledFields && !!disabledFields.first_name}
                                                                        required
                                                                        validate={
                                                                            shouldValidateMacedonian
                                                                                ? (value) => {
                                                                                    if (!value) {
                                                                                        return translate("validation.enter-tax-number");
                                                                                    }
                                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                    }
                                                                                }
                                                                                : undefined
                                                                        }
                                                                        parse={(value) => {
                                                                            if (!value) return "";

                                                                            let processedValue = value;

                                                                            if (shouldValidateMacedonian) {
                                                                                processedValue = transliterateToCyrilic(processedValue);
                                                                            }

                                                                            return capitalizeFirstLetter(processedValue);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-md-6">
                                                                    <Field
                                                                        component={InputField}
                                                                        label={translate("cart.wizard.last-name")}
                                                                        name="last_name"
                                                                        readOnly={disabledFields && !!disabledFields.last_name}
                                                                        required
                                                                        validate={
                                                                            shouldValidateMacedonian
                                                                                ? (value) => {
                                                                                    if (!value) {
                                                                                        return translate("validation.enter-tax-number");
                                                                                    }
                                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                    }
                                                                                }
                                                                                : undefined
                                                                        }
                                                                        parse={(value) => {
                                                                            if (!value) return "";

                                                                            let processedValue = value;

                                                                            if (shouldValidateMacedonian) {
                                                                                processedValue = transliterateToCyrilic(processedValue);
                                                                            }

                                                                            return capitalizeFirstLetter(processedValue);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {values.contact_type === CONTACT_DETAILS_TYPE_COMPANY && (
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <Field
                                                                        component={InputField}
                                                                        label={translate("cart.wizard.tax-number")}
                                                                        name="tax_number"
                                                                        validate={
                                                                            shouldValidateMacedonian
                                                                                ? (value) => {
                                                                                    if (!value) {
                                                                                        return translate("validation.enter-tax-number");
                                                                                    }
                                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                    }
                                                                                }
                                                                                : undefined
                                                                        }
                                                                        onChange={(event) => handleTaxNumberChange(event, form)}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="col-12">
                                                                    <Field
                                                                        component={InputField}
                                                                        label={translate("cart.wizard.company")}
                                                                        name="company"
                                                                        validate={
                                                                            shouldValidateMacedonian
                                                                                ? (value) => {
                                                                                    if (!value) {
                                                                                        return translate("validation.enter-company");
                                                                                    }
                                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                    }
                                                                                }
                                                                                : undefined
                                                                        }
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-md-6">
                                                                <PhoneNumberInput
                                                                    name="phone"
                                                                    label={translate("domain.domain-contacts.phone-number")}
                                                                    defaultCountry={"MK"}
                                                                    translate={translate}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-md-6">
                                                                <Field
                                                                    component={InputField}
                                                                    label={translate("cart.wizard.email")}
                                                                    name="email"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-9 col-md-12">
                                                                <Field
                                                                    component={InputField}
                                                                    label={translate("cart.wizard.address")}
                                                                    name="address1"
                                                                    required
                                                                    validate={
                                                                        shouldValidateMacedonian
                                                                            ? (value) => {
                                                                                if (!value) {
                                                                                    return translate("validation.enter-tax-number");
                                                                                }
                                                                                if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                    return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                }
                                                                            }
                                                                            : undefined
                                                                    }
                                                                    parse={(value) => {
                                                                        if (!value) return "";

                                                                        let processedValue = value;

                                                                        if (shouldValidateMacedonian) {
                                                                            processedValue = transliterateToCyrilic(processedValue);
                                                                        }

                                                                        return capitalizeFirstLetter(processedValue);
                                                                    }} />
                                                            </div>
                                                            <div className="col-xl-3 col-md-12">
                                                                <Field
                                                                    component={InputField}
                                                                    label={translate("domain.domain-contacts.appartment-number")}
                                                                    name="street_number"
                                                                    required
                                                                    validate={
                                                                        shouldValidateMacedonian
                                                                            ? (value) => {
                                                                                if (!value) {
                                                                                    return translate("validation.enter-tax-number");
                                                                                }
                                                                                if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                    return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                }
                                                                            }
                                                                            : undefined
                                                                    }
                                                                    parse={(value) => {
                                                                        if (!value) return "";

                                                                        let processedValue = value;

                                                                        if (shouldValidateMacedonian) {
                                                                            processedValue = transliterateToCyrilic(processedValue);
                                                                        }

                                                                        return capitalizeFirstLetter(processedValue);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-3 col-md-12">
                                                                <Field
                                                                    component={InputField}
                                                                    label={translate("cart.wizard.city")}
                                                                    name="city"
                                                                    required
                                                                    validate={
                                                                        shouldValidateMacedonian
                                                                            ? (value) => {
                                                                                if (!value) {
                                                                                    return translate("validation.enter-tax-number");
                                                                                }
                                                                                if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                    return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                }
                                                                            }
                                                                            : undefined
                                                                    }
                                                                    parse={(value) => {
                                                                        if (!value) return "";

                                                                        let processedValue = value;

                                                                        if (shouldValidateMacedonian) {
                                                                            processedValue = transliterateToCyrilic(processedValue);
                                                                        }

                                                                        return capitalizeFirstLetter(processedValue);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-xl-3 col-md-12">
                                                                <Field
                                                                    component={InputField}
                                                                    label={translate("cart.wizard.zip-code")}
                                                                    name="zip"
                                                                    required
                                                                    validate={
                                                                        shouldValidateMacedonian
                                                                            ? (value) => {
                                                                                if (!value) {
                                                                                    return translate("validation.enter-tax-number");
                                                                                }
                                                                            }
                                                                            : undefined
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-xl-6 col-md-12">
                                                                <Field
                                                                    component={SelectBox}
                                                                    label={translate("domain.domain-contacts.country")}
                                                                    options={countries}
                                                                    isSearchable={true}
                                                                    name="country"
                                                                    required
                                                                    onChange={(selectedOption) => handleCountryChange(selectedOption, form)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {shouldValidateMacedonian && (
                                                                    <StyledCyrillic>
                                                                        {translate("domain.domain-contacts.must-be-cyrillic")}
                                                                    </StyledCyrillic>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/* {restrictionMessage && (
                                                            <div className="row">
                                                                <div className="col-12 mt-3">
                                                                    <StyledRow>
                                                                        <Icon size="large" icon="warning" color="#fe9b34" />
                                                                        <StyledErrorText>{restrictionMessage}</StyledErrorText>
                                                                    </StyledRow>
                                                                </div>
                                                            </div>
                                                        )} */}
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                />
                            </TabContent>
                        </TabContainer>
                    )}
                    {activeTab === "option2" && (
                        <>
                            <StyledRow>
                                <Icon size="large" icon="warning" color="#fe9b34" />
                                <StyledErrorText>{translate("cart.wizard.account-info-modal.info-header")}</StyledErrorText>
                            </StyledRow>
                            <TabContainer>
                                <TabContent>
                                    <Form
                                        onSubmit={(values) => {
                                            console.log("Submitting values:", values); // Debugging line
                                            handleContactSubmit(values);
                                            setShowDataPreview(true);
                                        }}
                                        validate={validateContactDetails}
                                        initialValues={formData.option2}
                                        render={({ handleSubmit, values, form }) => (
                                            <form id="contact-details-form" onSubmit={handleSubmit}>
                                                {/* Administrative Contact */}
                                                <StyledContactHeader>
                                                    <div className="col-12 p-0 m-0 d-flex align-items-center">
                                                        <StyledContactTitle>{translate("cart.wizard.account-info-modal.administrative-contact")}</StyledContactTitle>
                                                        <StyledCustomTooltip>
                                                            <CustomTooltip content={translate("cart.wizard.account-info-modal.administrative-contact-tooltip")}>
                                                                <Icon size="l" icon="info" />
                                                            </CustomTooltip>
                                                        </StyledCustomTooltip>
                                                    </div>
                                                    {formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL && (
                                                        <div className="ml-auto">
                                                            <SecondaryButton
                                                                style={{ padding: "5px 15px", fontSize: "14px" }}
                                                                onClick={(e) => {
                                                                    e.preventDefault(); // Prevent form submission
                                                                    form.batch(() => {
                                                                        form.change("administrative_contact.name", formData.option1.first_name || "");
                                                                        form.change("administrative_contact.surname", formData.option1.last_name || "");
                                                                        form.change("administrative_contact.email", formData.option1.email || "");
                                                                        form.change("administrative_contact.phone", formData.option1.phone || "");
                                                                        form.change("administrative_contact.country", formData.option1.country || "");
                                                                    });
                                                                }}
                                                            >
                                                                {translate("cart.wizard.account-info-modal.copy-from-registrant")}
                                                            </SecondaryButton>
                                                        </div>
                                                    )}
                                                </StyledContactHeader>

                                                <div className="row">
                                                    <div className="col-6">
                                                        <Field
                                                            component={SelectBox}
                                                            label={translate("domain.domain-contacts.country")}
                                                            options={countries}
                                                            isSearchable={true}
                                                            name="administrative_contact.country"
                                                            required
                                                            onChange={handleCountryChangeAdministrative}
                                                            value={selectedAdministrativeCountry}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Field name="administrative_contact.name" component={InputField} label={translate("cart.wizard.first-name")} required validate={
                                                            shouldValidateMacedonianAdmin
                                                                ? (value) => {
                                                                    if (!value) {
                                                                        return translate("validation.enter-first-name");
                                                                    }
                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                    }
                                                                }
                                                                : undefined
                                                        }
                                                            parse={(value) => {
                                                                if (!value) return "";

                                                                let processedValue = value;

                                                                if (shouldValidateMacedonianAdmin) {
                                                                    processedValue = transliterateToCyrilic(processedValue);
                                                                }

                                                                return capitalizeFirstLetter(processedValue);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <Field name="administrative_contact.surname" component={InputField} label={translate("cart.wizard.last-name")} required
                                                            validate={
                                                                shouldValidateMacedonianAdmin
                                                                    ? (value) => {
                                                                        if (!value) {
                                                                            return translate("validation.enter-last-name");
                                                                        }
                                                                        if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                            return translate("domain.domain-contacts.must-be-cyrillic");
                                                                        }
                                                                    }
                                                                    : undefined
                                                            }
                                                            parse={(value) => {
                                                                if (!value) return "";

                                                                let processedValue = value;

                                                                if (shouldValidateMacedonianAdmin) {
                                                                    processedValue = transliterateToCyrilic(processedValue);
                                                                }

                                                                return capitalizeFirstLetter(processedValue);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <PhoneNumberInput
                                                            name="administrative_contact.phone"
                                                            label={translate("cart.wizard.phone-number")}
                                                            placeholder={translate("cart.wizard.phone-number")}
                                                            defaultCountry={shouldValidateMacedonianAdmin ? 'MK' : undefined}
                                                            translate={translate}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <Field
                                                            name="administrative_contact.email"
                                                            component={InputField}
                                                            label={translate("cart.wizard.email")}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                {/* Technical Contact */}
                                                <StyledContactHeader>
                                                    <div className="col-12 p-0 m-0 d-flex align-items-center">
                                                        <StyledContactTitle>
                                                            {translate("cart.wizard.account-info-modal.technical-contact")}
                                                        </StyledContactTitle>
                                                        <StyledCustomTooltip>
                                                            <CustomTooltip content={translate("cart.wizard.account-info-modal.technical-contact-tooltip")}>
                                                                <Icon size="l" icon="info" />
                                                            </CustomTooltip>
                                                        </StyledCustomTooltip>

                                                    </div>
                                                    {formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL && (
                                                        <div className="ml-auto">
                                                            <SecondaryButton
                                                                style={{ padding: "5px 15px", fontSize: "14px" }}
                                                                onClick={(e) => {
                                                                    e.preventDefault(); // Prevent form submission
                                                                    form.batch(() => {
                                                                        form.change("technical_contact.name", formData.option1.first_name || "");
                                                                        form.change("technical_contact.surname", formData.option1.last_name || "");
                                                                        form.change("technical_contact.email", formData.option1.email || "");
                                                                        form.change("technical_contact.phone", formData.option1.phone || "");
                                                                        form.change("technical_contact.country", formData.option1.country || "");
                                                                    });
                                                                }}
                                                            >
                                                                {translate("cart.wizard.account-info-modal.copy-from-registrant")}
                                                            </SecondaryButton>
                                                        </div>
                                                    )}
                                                    {formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY && (
                                                        values.administrative_contact &&
                                                        values.administrative_contact.name &&
                                                        values.administrative_contact.surname &&
                                                        values.administrative_contact.email &&
                                                        values.administrative_contact.phone &&
                                                        values.administrative_contact.country && (
                                                            <div className="ml-auto">
                                                                <SecondaryButton
                                                                    style={{ padding: "5px 15px", fontSize: "14px" }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); // Prevent form submission

                                                                        form.batch(() => {
                                                                            form.change("technical_contact.name", values.administrative_contact.name || "");
                                                                            form.change("technical_contact.surname", values.administrative_contact.surname || "");
                                                                            form.change("technical_contact.email", values.administrative_contact.email || "");
                                                                            form.change("technical_contact.phone", values.administrative_contact.phone || "");
                                                                            form.change("technical_contact.country", values.administrative_contact.country || "");
                                                                        });
                                                                    }}
                                                                >
                                                                    {translate("cart.wizard.account-info-modal.copy-from-administrative")}
                                                                </SecondaryButton>
                                                            </div>
                                                        )
                                                    )}
                                                </StyledContactHeader>

                                                <div className="row">
                                                    <div className="col-6">
                                                        <Field
                                                            component={SelectBox}
                                                            label={translate("domain.domain-contacts.country")}
                                                            options={countries}
                                                            isSearchable={true}
                                                            name="technical_contact.country"
                                                            required
                                                            onChange={handleCountryChangeTechnical}
                                                            value={selectedTechnicalCountry}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Field name="technical_contact.name" required component={InputField} label={translate("cart.wizard.first-name")} validate={
                                                            shouldValidateMacedonianTechnical
                                                                ? (value) => {
                                                                    if (!value) {
                                                                        return translate("validation.enter-first-name");
                                                                    }
                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                    }
                                                                }
                                                                : undefined
                                                        }
                                                            parse={(value) => {
                                                                if (!value) return "";

                                                                let processedValue = value;

                                                                if (shouldValidateMacedonianTechnical) {
                                                                    processedValue = transliterateToCyrilic(processedValue);
                                                                }

                                                                return capitalizeFirstLetter(processedValue);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <Field name="technical_contact.surname" required component={InputField} label={translate("cart.wizard.last-name")} validate={
                                                            shouldValidateMacedonianTechnical
                                                                ? (value) => {
                                                                    if (!value) {
                                                                        return translate("validation.enter-last-name");
                                                                    }
                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                    }
                                                                }
                                                                : undefined
                                                        }
                                                            parse={(value) => {
                                                                if (!value) return "";

                                                                let processedValue = value;

                                                                if (shouldValidateMacedonianTechnical) {
                                                                    processedValue = transliterateToCyrilic(processedValue);
                                                                }

                                                                return capitalizeFirstLetter(processedValue);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <PhoneNumberInput
                                                            name="technical_contact.phone"
                                                            label={translate("cart.wizard.phone-number")}
                                                            placeholder={translate("cart.wizard.phone-number")}
                                                            defaultCountry={shouldValidateMacedonianTechnical ? 'MK' : undefined}
                                                            translate={translate}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <Field name="technical_contact.email" required component={InputField} label={translate("cart.wizard.email")} />
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    />
                                </TabContent>
                            </TabContainer>
                        </>
                    )}
                    <StyledFooter className="d-flex justify-content-end w-100">
                        <SecondaryButton
                            className={isMobileOnly ? 'w-50 ' : ''}
                            style={{ marginRight: '15px' }}
                            onClick={() => {
                                if (activeTab === "option1") {
                                    setShowContactInfoModal(false);
                                } else {
                                    setActiveTab((prev) => {
                                        if (prev === "option2") return "option1";
                                        return prev;
                                    });
                                }
                            }}
                        >
                            {activeTab === "option1"
                                ? translate("cart.wizard.back-to-cart")
                                : translate("cart.wizard.go-back")}
                        </SecondaryButton>

                        <PrimaryButton
                            className={isMobileOnly ? 'w-50 ' : ''}
                            onClick={handleModalPrimaryClick}
                            disabled={!!restrictionMessageCheck && !isSubmitting}
                            submitting={isSubmitting}
                        >
                            {getPrimaryButtonLabel()}
                        </PrimaryButton>
                    </StyledFooter>
                    <div className="col-12 text-right">
                        <StyledHelp>{translate("cart.wizard.contact-us")}</StyledHelp>
                    </div>

                </>
            )}
        />
    )
}

const mapStateToProps = state => {
    return {
        countries: getCountriesFormatted(state),
    };
};

export default connect(
    mapStateToProps)(withRouter(withLocalize(ManageDomainContact)));

