import isEmpty from "lodash/isEmpty";
import trim from "validator/lib/trim";
import isUrl from "validator/lib/isURL";

import { getApplicationLocale } from "../services/localizationService";

import en from "../translations/en.json";
import mk from "../translations/mk.json";
const lang = {
  en: en,
  mk: mk
};

const resolvePath = (object, path, defaultValue) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

const translate = key =>
  resolvePath(lang[getApplicationLocale()], key, undefined);

export function validateRequired(value, fieldName) {
  if (!value || isEmpty(value) || !value.length) {
    return translate("validation.required");
  }

  return undefined;
}

export function validateCompany(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-company");
  }

  const name = trim(value);
  return minLength(3)(name);
}

export function validateTaxNumber(value) {
  // if (!value || isEmpty(value) || !value.length) {
  //   return translate("validation.enter-tax-number");
  // }

  // const name = trim(value);
  // return minLength(3)(name);
}

export function validateFirstName(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-first-name");
  }

  const name = trim(value);
  return minLength(3)(name);
}

export function validateLastName(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-last-name");
  }

  const name = trim(value);
  return minLength(3)(name);
}

export function validateCheckbox(value, errorMessageKey) {
  if (value === true) return undefined;
  if (value === false) return translate(errorMessageKey)
  if (value === undefined) return false;
}


export function validateFullName(value, required) {
  if (required) {
    if (!value || isEmpty(value)) {
      return translate("validation.enter-full-name");
    }
  }

  const fullName = value ? trim(value) : "";
  return minLength(3)(fullName);
}

export function validateEmail(value) {
  if (!value) {
    return translate("validation.enter-email");
  }
  const email = trim(value);

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(email)) {
    return translate("validation.invalid-email");
  }

  return undefined;
}

export function validateRole(value) {
  return undefined;
}

export function validateLoginPassword(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-password");
  }

  const password = trim(value);

  //if (password.length < 8) {
  //  return translate("Password must be at least 8 characters long and should contain least 1 uppercase letter, 1 number and 1 special character.";
  //}
  //
  //!@#$%^&*()-_+=.
  if (!password.match(/^.*$/)) {
    return translate(
      "Password must be at least 8 characters long and should contain least 1 uppercase letter, 1 number and 1 special character."
    );
  }

  return undefined;
}

export function validatePasswordSimple(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-password");
  }

  return undefined;
}

export function validatePassword(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-password");
  }

  const password = trim(value);

  // if (
  //     !password.match(
  //         /^(?=(?:.*[a-z]){1,})(?=(?:.*[A-Z]){1,})(?=(?:.*[!@#$%^&*()_+{}:"<>?|[\];',./`~]){2,}).{8,}$/
  //     )
  // ) {

  if (password.length < 8) {
    return translate("validation.password-minimum-requirements");
  }

  return undefined;
}

export function validatePasswordWithConfirm(value, confirmValue) {
  if (!confirmValue || isEmpty(value)) {
    return translate("validation.enter-confirm-password");
  }
  const password = trim(value);
  const confirmPassword = trim(confirmValue);

  // if (
  //     !password.match(
  //         /^(?=(?:.*[a-z]){1,})(?=(?:.*[A-Z]){1,})(?=(?:.*[!@#$%^&*()_+{}:"<>?|[\];',./`~]){2,}).{8,}$/
  //     )
  // ) {

  if (password.length < 8) {
    return translate("validation.password-minimum-requirements");
  }

  // if (
  //     !confirmPassword.match(
  //         /^(?=(?:.*[a-z]){1,})(?=(?:.*[A-Z]){1,})(?=(?:.*[!@#$%^&*()_+{}:"<>?|[\];',./`~]){2,}).{8,}$/
  //     )
  // ) {

  if (password.length < 8) {
    return translate("validation.password-minimum-requirements");
  }

  if (password !== confirmPassword) {
    return translate("validation.password-confirm-no-match");
  }

  return undefined;
}

export function validateNotRequiredPassword(value) {
  if (!value || isEmpty(value)) {
    return undefined;
  }

  const password = trim(value);

  if (validatePassword(password) !== undefined) {
    return validatePassword(password);
  }

  return undefined;
}

export function validateAddress(value, required) {
  if (required) {
    if (!value || isEmpty(value)) {
      //return translate("Please enter an Address.";
      return translate("validation.enter-address");
    }
  }

  const address = value ? trim(value) : "";
  return minLength(3)(address);
}

export function validateStreetNumber(value, required = true) {
  if (required) {
    if (!value || isEmpty(value)) {
      return translate("validation.enter-street-number");
    }
  }

  const streetNumber = value ? trim(value) : "";
  return minLength(1)(streetNumber);
}

export function validatePhoneNumber(value, required = true) {
  if (required) {
    if (!value || isEmpty(value) || value.length === 0) {
      return translate("validation.enter-phone-number-non-mk");
    }
  }

  const phone = trim(value);
  if (!phone.match(/^(\+){1}[0-9]{1,3}\.?[0-9]{8,12}$/gm)) {
    return translate("validation.enter-phone-number-non-mk");
  }

  return undefined;
}

//changed the regex to be from +389.71510856 with -> +38971510856
export function validateMkPhoneNumber(value, required = true) {
  if (required) {
    if (!value || isEmpty(value) || value.length === 0) {
      return translate("validation.enter-phone-number");
    }
  }

  const phone = trim(value);
  if (!phone.match(/^\+[0-9]{11,15}$/)) {
    return translate("validation.enter-phone-number");
  }

  return undefined;
}

export function validateWebsite(value) {
  if (!value || isEmpty(value)) {
    return translate("Please enter a Website.");
  }

  const website = trim(value);
  return isUrl(website, ["http", "https"])
    ? undefined
    : "Please enter a valid Website URL.";
}

export function validateCity(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-city");
  }
  const city = trim(value);
  return minLength(2)(city);
}

export function validateState(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-state");
  }
  const city = trim(value);
  return minLength(2)(city);
}

export function validateCountry(value) {
  // if (!value || isEmpty(value)) {
  //   return translate("validation.enter-country");
  // }
  // const country = trim(value);
  // return minLength(2)(country);
}

export function validateZipCode(value) {
  if (!value || isEmpty(value.toString())) {
    return translate("validation.enter-zip");
  }
  const zip = trim(value.toString());

  if (!/[a-zA-Z-0-9]{2,}/.test(zip)) {
    return translate("validation.invalid-zip");
  }

  return undefined;
}

export function validateTermsAndConditions(value) {
  if (!value || value === false) {
    return translate("validation.accept-terms");
  }
  return undefined;
}

export function validatePersonalDataIsCorrect(value) {
  if (!value || value === false) {
    return translate("validation.confirm-personal-data");
  }
  return undefined;
}

export function validateBiggerOrEqualThan(value, biggerThan, required = true) {
  if (required) {
    if (value === "") {
      return translate("validation.required");
    }
  }
  const valueToCheck = parseFloat(value);
  if (isNaN(valueToCheck)) {
    return translate("Please enter a valid value.");
  }
  if (valueToCheck < biggerThan) {
    return translate("Please enter a value bigger or equal than ") + biggerThan;
  }

  return undefined;
}

export function validateMaxAmount(value, max) {
  if (!value) {
    return translate("validation.enter-amount");
  }
  if (isNaN(value)) {
    return translate("validation.enter-amount");
  }

  if (value > max) {
    return `Please enter a value up to ${max}`;
  }

  return undefined;
}

export function validateRangeAmount(value, min, max) {
  if (!value) {
    return translate("validation.enter-amount-min").replace("{min}", min);
  }
  if (isNaN(value)) {
    return translate("validation.enter-amount");
  }

  if (value < min) {
    return translate("validation.enter-amount-min").replace("{min}", min);
  }

  if (value > max) {
    return translate("validation.enter-amount-max").replace("{max}", max);
  }

  return undefined;
}

export function validateNameserver(value, required = false) {
  if (required && !value) {
    return translate("validation.enter-nameserver");
  }
  if (value && /^(?![0-9]+$)(?!.*-$)(?!-)[a-zA-Z0-9-]{1,63}$/g.test(value)) {
    return translate("validation.enter-nameserver");
  }
  return undefined;
}

export function validateSubject(value, required) {
  if (required) {
    if (!value || isEmpty(value)) {
      return translate("validation.enter-subject");
    }
  }

  const subject = value ? trim(value) : "";
  return minLength(3)(subject);
}

export function validateMessage(value, required) {
  if (required) {
    if (!value || isEmpty(value)) {
      return translate("validation.enter-message");
    }
  }

  const message = value ? trim(value) : "";
  return minLength(3)(message);
}

// select box
export function validateDepartment(value, required) {
  if (required) {
    if (!value || !value.value) {
      return translate("validation.select-department");
    }
  }

  return undefined;
}

export function validateRelatedProduct(value, required) {
  if (required) {
    if (!value || !value.value) {
      return translate("validation.select-related-product");
    }
  }

  return undefined;
}

export function validatePriority(value, required) {
  if (!value || isEmpty(value.value)) {
    return translate("validation.enter-priority");
  }

  const priority = trim(value.value);
  return ["Low", "Medium", "High"].includes(priority)
    ? undefined
    : "validation.enter-priority";
}
//const maxLength = max => value =>
//  value && value.length > max
//    ? `Must be ${max} characters or less.`
//    : undefined;
const minLength = min => value =>
  value && value.length < min
    ? translate("validation.min-length").replace("{min}", min)
    : undefined;

// select box
export function validateSecurityQuestion(value, required) {
  if (required) {
    if (!value || !value.value) {
      return translate("validation.enter-securit-question.");
    }
  }

  return undefined;
}

export function validateSecurityAnswer(value, required) {
  if (required) {
    if (!value || isEmpty(value)) {
      return translate("validation.enter-security-answer");
    }
  }

  return minLength(1)(value);
}

export function validateSecurityAnswerConfirm(
  value,
  confirmationValue,
  required
) {
  if (required) {
    if (
      !value ||
      isEmpty(value) ||
      !confirmationValue ||
      isEmpty(confirmationValue)
    ) {
      return translate("validation.enter-security-answer");
    }

    if (value !== confirmationValue) {
      return translate("validation.enter-security-confirmation-answer");
    }
  }

  return minLength(1)(confirmationValue);
}

export function validateDomain(value) {
  if (!value || value.length === 0) {
    return translate("validation.enter-domain");
  }
  if (value.endsWith(".мкд")) {
    if (
      /^[\u0400-\u04FF0-9][\u0400-\u04FF0-9-]{1,61}[\u0400-\u04FF0-9](?:\.[\u0400-\u04FF]{2,})+$/g.test(
        value
      )
    ) {
      return undefined;
    }
    return translate("validation.invalid-cyrillic-latin-domain");
  }

  if (!value.endsWith("мкд")) {
    if (/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}(?:\.[a-zA-Z]{2,})+$/g.test(value)) {
      return minLength(1)(value);
    } else {
      if (
        /^[a-zA-Z0-9\u0400-\u04FF-]{0,61}(?:\.[a-zA-Z0-9\u0400-\u04FF]{2,})+$/g.test(
          value
        )
      ) {
        return translate("validation.invalid-cyrillic-latin-domain");
      }

      return translate("validation.enter-domain");
    }
  }

  return undefined;
}

export function validateDomainWithHttpOrHttps(value) {
  if (!value || value.length === 0) {
    return translate("validation.enter-full-url");
  }

  if (!value.startsWith("http://") && !value.startsWith("https://")) {
    return translate("validation.domain-must-have-protocol");
  }

  let domain = "";
  if (value.startsWith("http://")) {
    domain = value.replace(/http:\/\//gi, "");
  }

  if (value.startsWith("https://")) {
    domain = value.replace(/https:\/\//gi, "");
  }

  const fullUrl = domain;

  // extract domain only
  // we need this to check if it's a valid domain address
  // for example .мкд domains have to by all in cyrilic
  // while the rest of the url can be in latin

  let restOfUrl = "";

  if (fullUrl.indexOf("/") > -1) {
    domain = fullUrl.slice(0, fullUrl.indexOf("/"));
    restOfUrl = fullUrl.slice(fullUrl.indexOf("/"));
  }

  if (domain.endsWith(".мкд")) {
    if (
      /^[\u0400-\u04FF0-9][\u0400-\u04FF0-9-]{1,61}[\u0400-\u04FF0-9](?:\.[\u0400-\u04FF]{3,}[\/]*)+$/g.test(
        domain
      )
    ) {
      return undefined;
    }
    return translate("validation.invalid-cyrillic-latin-domain");
  }

  if (!domain.endsWith("мкд")) {
    if (
      /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}(?:\.[a-zA-Z]{2,}[\/]*)+$/g.test(domain)
    ) {
      return minLength(1)(domain);
    } else {
      if (
        /^[a-zA-Z0-9\u0400-\u04FF-]{0,61}(?:\.[a-zA-Z0-9\u0400-\u04FF]{2,})+$/g.test(
          domain
        )
      ) {
        return translate("validation.invalid-cyrillic-latin-domain");
      }

      return translate("validation.enter-domain");
    }
  }

  if (restOfUrl.length === 0) {
    return undefined;
  }

  if (!/^[a-z0-9.\-_/?=#@]*$/gm.test(restOfUrl)) {
    return translate("validation.enter-full-url");
  }

  return undefined;
}

export function validateOnlyDomainName(value, extension) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-domain");
  }

  if (!extension || isEmpty(extension)) {
    return translate("validation.enter-extension");
  }

  if (Array.isArray(extension) && extension.length === 0) {
    return translate("validation.enter-extension");
  }

  const name = trim(value);

  if (extension.endsWith("мкд")) {
    if (name.length <= 2) {
      return translate("validation.mkd-domain-must-be-min-3-chars");
    }

    if (/^[\u0400-\u04FF0-9][\u0400-\u04FF0-9-]{0,61}$/g.test(name)) {
      return undefined;
    }

    return translate("validation.invalid-cyrillic-latin-domain");
  }

  if (extension.endsWith("al")) {
    if (name.length < 2) {
      return translate("validation.al-domain-must-be-min-2-chars");
    }
  }

  if (!extension.endsWith("мкд")) {
    if (/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}$/g.test(value)) {
      return minLength(1)(name);
    } else {
      if (/^[a-zA-Z0-9\u0400-\u04FF-]{0,61}$/g.test(name)) {
        return translate("validation.invalid-cyrillic-latin-domain");
      }

      return undefined;
    }
  }

  return minLength(3)(name);
}

export function validateExtension(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.select-extension");
  }

  if (Array.isArray(value) && value.length === 0) {
    return translate("validation.select-extension");
  }

  return undefined;
}

export function validateDnsRecordType(value) {
  if (!value || isEmpty(value) || value.length === 0) {
    return translate("validation.required");
  }
  return undefined;
}

export function validateDnsRecordHostname(value, required = true) {
  if (!value || isEmpty(value)) {
    return translate("validation.required");
  }
  // prettier-ignore
  if (!/^(?!-)(?=[a-zA-Z0-9-._\u0400-\u04FF\u0400-\u04FF]{1,63}$)(?!.*[-]$)(?!.*--).+$/gm.test(value)) {
    return translate("validation.enter-valid-hostname");
  }
  return undefined;
}

export function validateDnsRecordHostnameValue(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.required");
  }
  // prettier-ignore
  if (!/^(?!-)(?=[a-zA-Z0-9-._\u0400-\u04FF\u0400-\u04FF]{1,255}$)(?!.*[-]$)(?!.*--).+$/gm.test(value)) {
    return translate("validation.enter-valid-hostname-value");
  }
  return undefined;
}

export function validateDnsRecordTTL(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.required");
  }

  if (isNaN(value)) {
    return translate("validation.enter-valid-ttl");
  }

  const ttl = parseInt(value, 10);
  if (ttl < 300 || ttl > 2592000) {
    return translate("validation.enter-valid-ttl");
  }
  return undefined;
}

export function validateDnsIP4Address(value, required = true) {
  if (required) {
    if (!value || isEmpty(value)) {
      return translate("validation.required");
    }
    if (!/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/gm.test(value)) {
      return translate("validation.enter-valid-ipv4");
    }
  } else {
    if (value && !isEmpty(value)) {
      if (!/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/gm.test(value)) {
        return translate("validation.enter-valid-ipv4");
      }
    } else {
      return undefined;
    }
  }
  return undefined;
}

export function validateDnsIP6Address(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.required");
  }

  if (
    !/^((?=.*::)(?!.*::.+::)(::)?([\dA-F]{1,4}:(:|\b)|){5}|([\dA-F]{1,4}:){6})((([\dA-F]{1,4}((?!\3)::|:\b|$))|(?!\2\3)){2}|(((2[0-4]|1\d|[1-9])?\d|25[0-5])\.?\b){4})$/i.test(
      value
    )
  ) {
    return translate("validation.enter-valid-ipv6");
  }
  return undefined;
}

export function validateDnsRecordPriority(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.required");
  }

  if (isNaN(value)) {
    return translate("validation.enter-valid-priority");
  }

  const priority = parseInt(value, 10);
  if (priority < 0 || priority > 100) {
    return translate("validation.enter-valid-priority");
  }
  return undefined;
}

export function validateDnsRecordFlags(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.required");
  }

  if (isNaN(value)) {
    return translate("validation.enter-valid-flags");
  }

  const flags = parseInt(value, 10);
  if (flags < 0 || flags > 255) {
    return translate("validation.enter-valid-flags");
  }
  return undefined;
}

export function validateDnsRecordTag(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-valid-tag");
  }
  return undefined;
}

export function validateDnsRecordSSLIssuerTLD(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-valid-ssl-issuer-tld");
  }

  return undefined;
}

export function validateDnsRecordWeight(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.required");
  }

  if (isNaN(value)) {
    return translate("validation.enter-valid-weight");
  }

  const weight = parseInt(value, 10);
  if (weight < 0 || weight > 100) {
    return translate("validation.enter-valid-weight");
  }
  return undefined;
}

export function validateDnsRecordPort(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.required");
  }

  if (isNaN(value)) {
    return translate("validation.enter-valid-port");
  }

  const weight = parseInt(value, 10);
  if (weight < 0 || weight > 100000) {
    return translate("validation.enter-valid-port");
  }
  return undefined;
}

export function validateDnsRecordTextInformation(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.required");
  }

  if (!value.startsWith('"') || !value.endsWith('"')) {
    return translate("validation.enter-valid-text-information");
  }
  return undefined;
}

export function validateInArray(value, arr) {
  if (!value || isEmpty(value)) {
    return translate("validation.required");
  }

  if (arr.indexOf(value) !== -1) {
  }
}

export const normalizeMacedonianPhoneNumber = (phone) => {
  if (!phone) return phone;

  const cleanedPhone = phone.replace(/[^\d+]/g, '');

  if (cleanedPhone.startsWith('+389')) {
    return '+389.' + cleanedPhone.slice(4); // Fixed: Removed .Fields
  }

  return phone;
};
