import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Container from "../../../../components/Containers/Container";
import Icon from "../../../../components/Icon";
import { theme } from "../../../../design/Themes";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import iconDownload from "../../../../assets/images/icon-download.svg";
import iconUpload from "../../../../assets/images/icon-upload.svg";
import iconViewSignRequest from "../../../../assets/images/icon-view-signrequest.svg";
import { Field, Form } from "react-final-form";
import InputField from "../../../../components/InputField";
import { assignItemDeliveryType, fetchCustomerPersonalInformation, uploadDocumentSignedV2, signDocumentEvrotrust, checkDocumentSignedEvrotrust, assignTemplateToDomain, getDomainTemplateById } from "../../../../services/customerService";
import { withRouter } from "react-router-dom";
import { ROUTE_CART_CHECKOUT } from "../../../../routes/routes";
import { getCartId } from "../../../../services/cartService";
import { Translate, withLocalize } from "react-localize-redux";
import en from "../../../../translations/en.json";
import mk from "../../../../translations/mk.json";
import { getApplicationLocale } from "../../../../services/localizationService";
import { isMobileOnly } from "react-device-detect";
import { validateAddress, validateCity, validateFullName, validateMkPhoneNumber, validateStreetNumber, validateZipCode } from "../../../../common/validationRules";
import FileUploadButton from "../../../../components/FileUploadButton/FileUploadButton";
import PhoneNumberInput from "../../../../components/PhoneNumberInput/PhoneNumberInput";

// Lang implementation
const lang = {
  en: en,
  mk: mk
};

const resolvePath = (object, path, defaultValue) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

const translate = key =>
  resolvePath(lang[getApplicationLocale()], key, undefined);


const StyledWrapper = styled.div`
  padding: ${isMobileOnly ? '10px 15px' : '30px 50px;'}
  ${isMobileOnly ? 'text-align: center' : ''}
  ${isMobileOnly ? 'display: flex' : ''}
  ${isMobileOnly ? 'flex-direction: column' : ''}
  ${isMobileOnly ? 'align-items: center' : ''}
`;

const StyledDescription = styled.div`
  font-size: ${isMobileOnly ? '12px' : '14px;'}
  color: ${theme.neutral6};
  margin-bottom: 20px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${isMobileOnly ? `
    flex-direction: column;
    align-items: stretch;
  ` : ''}
`;

const StyledRowEnd = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  &.justify-end {
    justify-content: flex-end;
  }

  ${isMobileOnly ? `
    flex-direction: column;
    align-items: stretch;
  ` : ''}
`;

const StyledText = styled.div`
  font-size: ${isMobileOnly ? '16px' : '23px;'}
  color: ${theme.neutralBase};
  line-height: 1.2;
  display: flex;
  align-items: center;
`;

const StyledSmallText = styled.div`
  font-size: ${isMobileOnly ? '12px' : '14px;'}
  color: ${theme.neutralBase};
  line-height: 1.2;
  display: flex;
  align-items: center;
`;

const StyledFooter = styled.div`
  padding-bottom: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const StyledList = styled.ul`
  margin: 10px 0 20px 20px;
  padding: 0px 10px;
  list-style-type: disc;
  color: ${theme.neutral6};

  li {
    ${isMobileOnly ? 'font-size: 12px;' : ``}
    margin-bottom: 10px;
    line-height: 1.5;
  }
`;

const StyledUnderline = styled.span`
  text-decoration: underline;
  margin-left: 5px;
`;

const TabContainer = styled.div`
  margin-top: 20px;
`;

const TabButtons = styled.div`
  display: flex;
  gap: ${isMobileOnly ? '5px' : '15px'}
`;

const TabButton = styled.button`
  background-color: ${({ active }) => (active ? theme.blue5 : theme.neutral2)};
  border: none;
  padding: ${isMobileOnly ? '5px 10px' : '10px 25px;'}
  border-radius: 4px 4px 0px 0px;
  font-size: ${isMobileOnly ? '12px ' : '14px;'}
  font-weight: bold;
  color: ${({ active }) => (active ? theme.white : theme.neutralBase)};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${theme.blue5};
    color: ${theme.white};
  }
`;

const TabContent = styled.div`
  font-size: 14px;
  color: ${theme.neutral6};
`;

const StyledWrapperNoBottomPadding = styled.div`
  padding: 30px 50px 0px 50px;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  ${isMobileOnly ? `
    flex-direction: column;
    gap: 10px;
  ` : ''}
`;

const StyledTabDescriptionText = styled.div`
  font-size: 14px;
  color: ${theme.neutral5};
  margin-bottom: 20px;
  max-width: 100%;
  overflow-wrap: break-word;
  word-break: break-word;
  ${isMobileOnly && `
    white-space: normal;      
  `};
`;

const StyledPrimaryButton = styled(SecondaryButton)`
  color: ${props => (props.active ? theme.blue5 : theme.neutral2)};
  &:hover {
    color: ${theme.blue5};
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  color: ${props => (props.active ? theme.blue5 : theme.neutral2)};
  &:hover {
    color: ${theme.blue5};
  }
`;

const StyledPrimaryButtonNoTwoRows = styled(PrimaryButton)`
`;

const StyledFileInfo = styled.div`
  margin-left: 10px;
  font-size: 12px;
  color: ${theme.neutral5};
  display: flex;
  align-items: center;
  max-width: 100%;          /* Prevent overflow */
  overflow: hidden;         /* Hide excess content */
  text-overflow: ellipsis;  /* Add ellipsis for long text */
  white-space: nowrap;      /* Keep it on one line with ellipsis */
  
  ${isMobileOnly && `
    white-space: normal;    /* Allow wrapping on mobile */
    word-break: break-word; /* Break long words */
  `}
`;

const StyledContainer = styled.div`

`;

const StyledErrorText = styled.div`
  font-size: 12px;
  color: ${theme.red2};
`;


const DomainRegister = ({ onGoBack, onNextStep, products, history, s3_presigned_url, updateS3PresignedUrl }) => {

  const isMacedoniaDomain = (domain) => {
    const macedonianTLDs = ['.mk', '.мкд', '.com.mk', '.org.mk', '.net.mk', '.edu.mk', '.inf.mk'];
    return macedonianTLDs.some(tld => domain.endsWith(tld));
  };
  const domainProducts = products.filter(product => product.type.includes("domain"));


  useEffect(() => {
    const domainProducts = products.filter(product => product.type.includes("domain"));
    if (domainProducts.length === 0) {
      history.push(ROUTE_CART_CHECKOUT)
    }
  }, [products, history]);

  useEffect(() => {
    console.log("s3_presigned_url updated:", s3_presigned_url);
  }, [s3_presigned_url]);
  const [savedCourierData, setSavedCourierData] = useState({}); // Tracks saved courier data per domain
  const [isFormEditable, setIsFormEditable] = useState(
    products.reduce((acc, product) => {
      if (product.type.includes("domain")) {
        acc[product.domain] = true;
      }
      return acc;
    }, {})
  );
  const [isCourierCompleted, setIsCourierCompleted] = useState({});
  const [courierFormData, setCourierFormData] = useState({});
  const [isDownloaded, setIsDownloaded] = useState({});
  const [isUploaded, setIsUploaded] = useState({});
  const [isCompleted, setIsCompleted] = useState({});
  const [selectedFiles, setSelectedFiles] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState({});
  const [isSigned, setIsSigned] = useState({});
  const [templateTypes, setTemplateTypes] = useState({}); // New state to track template types
  const [activeTab, setActiveTab] = useState("option1");
  const [courierFormErrors, setCourierFormErrors] = useState({}); // New state for form errors

  const [activeTabs, setActiveTabs] = useState(
    products.reduce((acc, product) => {
      if (product.type.includes("domain")) {
        acc[product.domain] = "option1";
      }
      return acc;
    }, {})
  );

  useEffect(() => {
    const fetchPresignedUrls = async () => {
      let updatedUrls = { ...s3_presigned_url };

      for (const product of products) {
        if (product.config && product.config.type === "transfer") {
          try {
            console.log(`📢 Fetching presigned URL for transfer domain: ${product.domain}`);

            const response = await assignTemplateToDomain(
              "", // No template_id for transfer domains
              product.id,
              getCartId()
            );

            if (
              isMacedoniaDomain(product.domain) &&
              response.data &&
              response.data.data &&
              response.data.data.marnet_document
            ) {
              updatedUrls[product.domain] = response.data.data.marnet_document;
              console.log(`🔗 Presigned URL fetched for transfer domain: ${product.domain}`);
            } else {
              console.warn(`⚠️ No presigned URL available for ${product.domain}`);
            }
          } catch (error) {
            console.error(`❌ Error fetching presigned URL for ${product.domain}:`, error);
          }
        }
      }

      // Update state with fetched URLs
      updateS3PresignedUrl(updatedUrls);
    };

    fetchPresignedUrls();
  }, [products]);

  // Fetch template types for each domain
  const fetchTemplateTypes = async () => {
    const cartId = getCartId();
    for (const product of domainProducts) {
      if (isMacedoniaDomain(product.domain)) {
        try {
          const response = await getDomainTemplateById(product.id, cartId);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.template
          ) {

            console.log(response)
            console.log(response.data.data.template.registrant.country)
            setTemplateTypes(prev => ({
              ...prev,
              [product.domain]: {
                type: response.data.data.template.type || "unknown",
                country: response.data.data.template.registrant.country || "unknown"
              }
            }));
          }
        } catch (error) {
          console.error(`Error fetching template for ${product.domain}:`, error);
        }
      }
    }
  };

  useEffect(() => {
    console.log('test')
    const domainProducts = products.filter(product => product.type.includes("domain"));
    if (domainProducts.length === 0) {
      history.push(ROUTE_CART_CHECKOUT);
    }


    fetchTemplateTypes();
  }, [products, history]);

  const [activeButtons, setActiveButtons] = useState(
    products.reduce((acc, product) => {
      if (product.type.includes("domain")) {
        acc[product.domain] = "primary";
      }
      return acc;
    }, {})
  );

  // Validation function for courier fields
  const validateCourierDetails = (values, domain) => {
    const errors = {};
    const shouldValidateMacedonian = isMacedoniaDomain(domain);

    errors.name = validateFullName(values.name) || (shouldValidateMacedonian && !/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(values.name) ? translate("domain.domain-contacts.must-be-cyrillic") : undefined);
    errors.phone = validateMkPhoneNumber(values.phone);
    errors.address = validateAddress(values.address) || (shouldValidateMacedonian && !/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(values.address) ? translate("domain.domain-contacts.must-be-cyrillic") : undefined);
    errors.street = (shouldValidateMacedonian && !/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(values.street) ? translate("domain.domain-contacts.must-be-cyrillic") : undefined);
    errors.city = validateCity(values.city) || (shouldValidateMacedonian && !/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(values.city) ? translate("domain.domain-contacts.must-be-cyrillic") : undefined);
    errors.postalCode = validateZipCode(values.postalCode) || (shouldValidateMacedonian && !/^\d{4}$/.test(values.postalCode) ? translate("validation.zip-code-numeric") : undefined);

    return errors;
  };

  const canProceed = () => {
    return products.every(product => {
      if (!product.type.includes("domain")) return true;

      const domain = product.domain;
      const templateType = templateTypes[domain] ? templateTypes[domain].type : undefined;

      if (!isMacedoniaDomain(domain)) return true;

      if (templateType === "individual") {
        if (activeTabs[domain] === "option1" && activeButtons[domain] === "primary" && isSigned[domain]) return true;
        if (activeTabs[domain] === "option1" && activeButtons[domain] === "secondary" && isUploaded[domain]) return true;
        if (activeTabs[domain] === "option3" && isDownloaded[domain]) return true;
        return false;
      }

      if (activeTabs[domain] === "option1" && activeButtons[domain] === "primary" && isSigned[domain]) return true;
      if (activeTabs[domain] === "option1" && activeButtons[domain] === "secondary" && isUploaded[domain]) return true;
      if (activeTabs[domain] === "option3" && isDownloaded[domain]) return true;

      if (activeTabs[domain] === "option2") {
        return savedCourierData[domain] && isDownloaded[domain];
      }

      return false;
    });
  };
  const handleSaveCourierForm = (domain, values) => {
    const errors = validateCourierDetails(values, domain);
    if (Object.keys(errors).every(key => !errors[key])) {
      setSavedCourierData(prev => ({ ...prev, [domain]: values }));
      setIsFormEditable(prev => ({ ...prev, [domain]: false }));
      setIsCourierCompleted(prev => ({ ...prev, [domain]: true }));
    } else {
      alert(translate("cart.wizard.domain-register-modal.error-all-fields-required"));
    }
  };

  const handleUseSameAddress = (domain, savedDomain) => {
    const savedData = savedCourierData[savedDomain];
    setCourierFormData(prev => ({ ...prev, [domain]: savedData }));
    setSavedCourierData(prev => ({ ...prev, [domain]: savedData }));
    setIsFormEditable(prev => ({ ...prev, [domain]: false }));
    setIsCourierCompleted(prev => ({ ...prev, [domain]: true }));
  };

  const handleEditCourierForm = (domain) => {
    setIsFormEditable(prev => ({ ...prev, [domain]: true }));
  };

  const handleCourierInputChange = (domain, field, value) => {
    setCourierFormData(prev => {
      const updatedForm = {
        ...prev,
        [domain]: { ...prev[domain], [field]: value }
      };
      const errors = validateCourierDetails(updatedForm[domain] || {}, domain);
      const isValid = Object.keys(errors).every(key => !errors[key]);
      setIsCourierCompleted(prev => ({ ...prev, [domain]: isValid }));
      setCourierFormErrors(prev => ({ ...prev, [domain]: errors }));
      return updatedForm;
    });
  };

  const handleSendEvrotrustRequest = async (phone, domain) => {
    // setIsUploaded((prev) => ({ ...prev, [domain]: true }));
    // setIsSigned((prev) => ({ ...prev, [domain]: true }));

    try {
      const product = products.find((p) => p.type.includes("domain") && p.domain === domain);
      const itemId = product ? product.id : null;
      console.log('Product: ', product);
      console.log('ItemId:', itemId);
      const cartId = getCartId();

      if (!phone) {
        alert(translate("cart.wizard.domain-register-modal.error-enter-phone-number"));
        return;
      }

      setIsSendingRequest(true);

      const response = await signDocumentEvrotrust({ item_id: itemId, cart: cartId, phone });

      console.log(response)
      if (response.data && response.data.error === false) {
        let message =
          response.data &&
            response.data.data &&
            response.data.data.messages &&
            response.data.data.messages[0]
            ? response.data.data.messages[0]
            : translate("cart.wizard.domain-register-modal.error-while-uploading-document-1");

        alert(message);
        // ✅ Mark the domain as successfully signed
        setIsUploaded((prev) => ({ ...prev, [domain]: true }));
        setIsSigned((prev) => ({ ...prev, [domain]: true }));
      } else {
        let errorMessage =
          response.data &&
            response.data.data &&
            response.data.data.messages &&
            response.data.data.messages[0]
            ? response.data.data.messages[0]
            : translate("cart.wizard.domain-register-modal.error-while-uploading-document-1");

        alert(errorMessage);
        console.error("Error:", errorMessage);

        // If user is not found, disable the input
        if (errorMessage.includes("User not found")) {
          setIsUploaded((prev) => ({ ...prev, [domain]: true }));
          setPhoneNumbers((prev) => ({ ...prev, [domain]: phone }));
        }
      }
    } catch (error) {
      console.error("Request failed:", error);

      if (error.response && error.response.status === 400) {
        let errorMessage =
          error.response.data &&
            error.response.data.data &&
            error.response.data.data.messages &&
            error.response.data.data.messages[0]
            ? error.response.data.data.messages[0]
            : translate("cart.wizard.domain-register-modal.error-while-uploading-document-1");

        alert(`${errorMessage}`);
      } else {
        alert(translate("cart.wizard.domain-register-modal.error-uknown"));
      }
    } finally {
      setIsSendingRequest(false);
    }
  };



  const uploadDocumentSignedRequest = async (domain, file, product) => {
    if (!isMacedoniaDomain(domain)) {
      console.log(`🚫 Skipping upload for non-Macedonian domain: ${domain}`);
      return;
    }

    if (!file) {
      console.error(`❌ No file selected for ${domain}.`);
      alert(translate("cart.wizard.domain-register-modal.error-file-is-not-selected"));
      return;
    }

    try {
      const customerData = await fetchCustomerPersonalInformation();

      // ✅ Use traditional checks instead of `?.`
      if (!customerData || !customerData.data || !customerData.data.data || !customerData.data.data.customer || !customerData.data.data.customer.id) {
        throw new Error("Не можам да го најдам клиентскиот ID. Обиди се повторно.");
      }
      const isTransfer = product.config && product.config.type === "transfer";
      console.log(isTransfer)
      const clientId = customerData.data.data.customer.id;
      const deliveryType =
        activeTabs[domain] === "option1"
          ? "digital-manual"
          : activeTabs[domain] === "option2"
            ? "cargo"
            : activeTabs[domain] === "option3"
              ? "in-person"
              : "unset-delivery-type";

      const formData = new FormData();
      formData.append("domain", domain);
      formData.append("client_id", clientId);
      formData.append("delivery_type", deliveryType);
      formData.append("signature_status", "signed");
      formData.append("domain_action", isTransfer ? "transfer" : "register"); // Adjust based on domain type
      formData.append("document_type", "domain-form");
      formData.append("attachment", file);

      // console.log("Uploading document with FormData:", Object.fromEntries(formData.entries()));

      const response = await uploadDocumentSignedV2(formData);

      if (!response || !response.data) {
        throw new Error("Неуспешно прикачување на документ.");
      }

      console.log("✅ Upload successful for:", domain);
      setIsUploaded((prev) => ({ ...prev, [domain]: true }));
    } catch (error) {
      console.error(`❌ Грешка при праќање на документ за ${domain}:`, error);
      alert(translate("cart.wizard.domain-register-modal.error-while-uploading-document-1"));
    }
  };


  const handleNextStep = async () => {
    const cartId = getCartId();
    setIsSaving(true);

    try {
      const itemsToAssign = [];

      for (const product of products) {
        if (!product.type.includes("domain")) continue;

        const domain = product.domain;
        const selectedOption = activeTabs[domain];
        const selectedButton = activeButtons[domain];
        const itemId = product.id;

        let deliveryType;
        let additionalData = {};

        if (selectedOption === "option1" && selectedButton === "primary" && isMacedoniaDomain(domain)) {
          deliveryType = "digital-evrotrust";
          try {
            const response = await checkDocumentSignedEvrotrust({ item_id: itemId, cart: cartId });
            if (!response) {
              alert(translate("cart.wizard.domain-register-modal.error-signing-required"));
              setIsSaving(false);
              return;
            }
          } catch (error) {
            console.error("Error checking signed document:", error);
            alert(translate("cart.wizard.domain-register-modal.error-signing-required"));
            setIsSaving(false);
            return;
          }
        } else if (selectedOption === "option1" && selectedButton === "secondary") {
          deliveryType = "digital-manual";
        } else if (selectedOption === "option2") {
          deliveryType = "cargo";
          const savedData = savedCourierData[domain] || {};
          if (!savedData.name || !savedData.phone || !savedData.address || !savedData.street || !savedData.city || !savedData.postalCode) {
            alert(translate("cart.wizard.domain-register-modal.error-all-fields-required") + ` ${domain}`);
            setIsSaving(false);
            return;
          }
          additionalData = {
            name: savedData.name,
            phone: savedData.phone,
            address: savedData.address,
            street_number: savedData.street,
            city: savedData.city,
            zip_code: savedData.postalCode,
          };
        } else if (selectedOption === "option3") {
          deliveryType = "in-person";
        } else {
          deliveryType = "unset-delivery-type";
        }

        if (!isMacedoniaDomain(domain)) continue;

        if (selectedOption === "option1" && selectedButton === "secondary") {
          const file = selectedFiles[domain] && selectedFiles[domain].file;
          if (!file) {
            alert(translate("cart.wizard.domain-register-modal.error-document-not-uploaded") + ` ${domain}.`);
            setIsSaving(false);
            return;
          }
          await uploadDocumentSignedRequest(domain, file, product);
        }

        if ((selectedOption === "option2" || selectedOption === "option3") && !isDownloaded[domain]) {
          alert(translate("cart.wizard.domain-register-modal.error-document-not-downloaded") + ` ${domain}`);
          setIsSaving(false);
          return;
        }

        // Flatten additionalData into the item object
        itemsToAssign.push({
          item_id: itemId,
          delivery_type: deliveryType,
          ...(Object.keys(additionalData).length > 0 && additionalData), // Spread the fields directly
        });

      }

      if (itemsToAssign.length > 0) {
        console.log("Sending items to assignItemDeliveryType:", itemsToAssign); // Debugging log
        const response = await assignItemDeliveryType(itemsToAssign, cartId);
        console.log("assignItemDeliveryType response:", response.data); // Debugging log
      }

      onNextStep();
    } catch (error) {
      console.error("❌ Error processing:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
      }
      alert(translate("cart.wizard.domain-register-modal.error-uknown"));
    } finally {
      setIsSaving(false);
    }
  };

  const handleDownloadRequest = (product) => {
    if (!product) {
      alert(translate("cart.wizard.domain-register-modal.error-document-not-ready"));
      return;
    }

    // Use the pre-fetched presigned URL
    const presignedUrl = s3_presigned_url[product.domain];

    if (presignedUrl) {
      window.open(presignedUrl, "_blank");
      setIsDownloaded(prev => ({ ...prev, [product.domain]: true }));
    } else {
      alert(translate("cart.wizard.domain-register-modal.error-document-not-ready"));
    }
  };

  const validatePhoneNumber = (value) => {
    const result = validateMkPhoneNumber(value, true);
    return result;
  };

  const handleFileUpload = (file, domain) => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    console.log(`Selected file for ${domain}:`, file);

    setSelectedFiles((prev) => ({
      ...prev,
      [domain]: { name: file.name, size: (file.size / 1024).toFixed(2) + " KB", file },
    }));

    setIsUploaded((prev) => ({ ...prev, [domain]: true }));
  };

  return (
    <Translate>
      {({ translate }) => (
        <div className="row">
          <div className="col-12 ml-md-3">
            {/* <PrimaryButton onClick={handleButtonClickTestGtag} /> */}
            <StyledRow>
              <Icon size="large" icon="warning" color="#fe9b34" />
              <StyledText>{translate("cart.wizard.domain-register-modal.signing-required-info")}</StyledText>
            </StyledRow>
            <StyledDescription>
              {translate("cart.wizard.domain-register-modal.rules-guide-info")}:
            </StyledDescription>
            <StyledList>
              <li>{translate("cart.wizard.domain-register-modal.submiting-request-info")}</li>
              <li>{translate("cart.wizard.domain-register-modal.submiting-request-info-sec")}</li>
            </StyledList>
            {products.map(product => (
              product.type.includes("domain") && (
                isMacedoniaDomain(product.domain) ? (
                  <div className="mt-5" style={{ opacity: isCompleted[product.domain] ? 0.5 : 1, pointerEvents: isCompleted[product.domain] ? "none" : "auto" }}>
                    <Container>
                      <StyledWrapperNoBottomPadding>
                        <StyledText>{translate("cart.wizard.domain-register-modal.sign-document-for-first")}  <StyledUnderline> {product.domain}</StyledUnderline></StyledText>
                        <StyledDescription>
                          {translate("cart.wizard.domain-register-modal.sign-document-for-sec")}
                        </StyledDescription>
                        <TabContainer>
                          <TabButtons>
                            <TabButton
                              active={activeTabs[product.domain] === "option1"}
                              onClick={() => {
                                const updatedTabs = { ...activeTabs, [product.domain]: "option1" };
                                setActiveTabs(updatedTabs);
                              }}
                            >
                              {translate("cart.wizard.domain-register-modal.digital-signing-label")}
                            </TabButton>
                            {templateTypes[product.domain] && templateTypes[product.domain].type !== "individual" && templateTypes[product.domain].country === "Macedonia" && (
                              <TabButton
                                active={activeTabs[product.domain] === "option2"}
                                onClick={() => setActiveTabs(prev => ({ ...prev, [product.domain]: "option2" }))}
                              >
                                {translate("cart.wizard.domain-register-modal.courier-label")}
                              </TabButton>
                            )}
                            <TabButton
                              active={activeTabs[product.domain] === "option3"}
                              onClick={() => setActiveTabs(prev => ({ ...prev, [product.domain]: "option3" }))}
                            >
                              {translate("cart.wizard.domain-register-modal.in-person")}
                            </TabButton>
                          </TabButtons>
                        </TabContainer>
                      </StyledWrapperNoBottomPadding>
                    </Container>
                    <Container>
                      <StyledWrapper>
                        <TabContainer>
                          <TabContent>
                            {activeTabs[product.domain] === "option1" && (
                              <>
                                <StyledContainer className="col-12">
                                  <StyledText>{translate("cart.wizard.domain-register-modal.select-type-of-digital-sign")}</StyledText>
                                  <StyledButtonGroup>
                                    <StyledPrimaryButton
                                      onClick={() => setActiveButtons(prev => ({ ...prev, [product.domain]: "primary" }))}
                                      active={activeButtons[product.domain] === "primary"}
                                    >
                                      {translate("cart.wizard.domain-register-modal.with-evrotrust")}
                                    </StyledPrimaryButton>
                                    <StyledSecondaryButton
                                      onClick={() => setActiveButtons(prev => ({ ...prev, [product.domain]: "secondary" }))}
                                      active={activeButtons[product.domain] === "secondary"}
                                    >
                                      {translate("cart.wizard.domain-register-modal.with-kips-or-telecom-certified")}
                                    </StyledSecondaryButton>
                                  </StyledButtonGroup>
                                  {activeButtons[product.domain] === "primary" && (
                                    <>
                                      <StyledTabDescriptionText className="mt-4">

                                        <strong>{translate("cart.wizard.domain-register-modal.with-evrotrust-description-1")}</strong><br />
                                        {translate("cart.wizard.domain-register-modal.with-evrotrust-description-2")}
                                      </StyledTabDescriptionText>
                                      <StyledList>
                                        <li>{translate("cart.wizard.domain-register-modal.with-evrotrust-description-3")} <a href="https://mkhost.com/mk/other/knowledgebase/533/digitalno-potpishuvanje-na-baranja-za-mk-domeni-so-eideasy-i-evrotrust" target="_blank" rel="noopener noreferrer">{translate("cart.wizard.domain-register-modal.with-evrotrust-description-5")}</a>.</li>
                                        <li>{translate("cart.wizard.domain-register-modal.with-evrotrust-description-4")} <a href="https://www.youtube.com/watch?v=rHUMp7uOiHo&amp" target="_blank" rel="noopener noreferrer">{translate("cart.wizard.domain-register-modal.with-evrotrust-description-6")}</a>.</li>
                                      </StyledList>
                                      <SecondaryButton
                                        className={isMobileOnly ? 'w-100 ' : ''}
                                        onClick={() => handleDownloadRequest(product)}
                                      >
                                        <img src={iconUpload} alt="" className="mr-2" />
                                        {translate("cart.wizard.domain-register-modal.download-request-for-sign")}
                                      </SecondaryButton>
                                      <Form
                                        onSubmit={(values) => {
                                          console.log("Submitted Values:", values);
                                          handleSendEvrotrustRequest(values.phoneNumber, product.domain);
                                        }}
                                        render={({ handleSubmit, form, values }) => (
                                          <form onSubmit={(e) => e.preventDefault()}>
                                            <StyledTabDescriptionText className="mt-4">
                                              {translate("cart.wizard.domain-register-modal.input-phone-number")}
                                            </StyledTabDescriptionText>

                                            <StyledRow>
                                              <div className="col-xl-8 p-0 m-0">
                                                <Field
                                                  name="phoneNumber"
                                                  component={InputField}
                                                  label={translate("cart.wizard.phone-number")}
                                                  required
                                                  disabled={isUploaded[product.domain]}
                                                  validate={(val) => validatePhoneNumber(val)}
                                                />
                                              </div>

                                              <div className="col-xl-4 p-0 m-0">
                                                <StyledPrimaryButtonNoTwoRows
                                                  type="button"
                                                  submitting={isSendingRequest}
                                                  disabled={isSendingRequest || !(values && values.phoneNumber) || validatePhoneNumber(values && values.phoneNumber) || isUploaded[product.domain]}
                                                  className="mt-2"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleSendEvrotrustRequest(values && values.phoneNumber, product.domain);
                                                  }}
                                                >
                                                  {isSendingRequest ? translate("cart.wizard.domain-register-modal.sending") : translate("cart.wizard.domain-register-modal.send-request-to-evrotrust")}
                                                </StyledPrimaryButtonNoTwoRows>

                                              </div>
                                            </StyledRow>

                                            <StyledTabDescriptionText className="mt-4">
                                              {translate("cart.wizard.domain-register-modal.after-sending-info")}
                                            </StyledTabDescriptionText>
                                          </form>
                                        )}
                                      />

                                    </>
                                  )}
                                </StyledContainer>
                                {activeButtons[product.domain] === "secondary" && (
                                  <>
                                    <StyledTabDescriptionText className="mt-4">
                                      <strong>{translate("cart.wizard.domain-register-modal.digital-sign-first")}</strong> {translate("cart.wizard.domain-register-modal.digital-sign-sec")}<br />
                                    </StyledTabDescriptionText>
                                    <PrimaryButton
                                      onClick={() => handleDownloadRequest(product)}
                                    >
                                      <img src={iconDownload} alt="" className="mr-2" />
                                      {translate("cart.wizard.domain-register-modal.download-request-for-sign")}
                                    </PrimaryButton>

                                    <StyledRow className="mt-4">
                                      <FileUploadButton
                                        acceptFileExtensions=".pdf"
                                        onFileSelect={(file) => handleFileUpload(file, product.domain)}
                                        label="cart.wizard.domain-register-modal.upload-request-signed"
                                        disabled={!isDownloaded[product.domain]}
                                      />

                                      {/* <SecondaryButton
                                        className={isMobileOnly ? 'w-100 ' : ''}
                                        disabled={!isDownloaded[product.domain]}
                                        onClick={() => {
                                          if (fileInputRefs.current[product.domain]) {
                                            fileInputRefs.current[product.domain].click();
                                          }
                                        }}
                                      >
                                        <img src={iconViewSignRequest} alt="" className="mr-2" />
                                        {translate("cart.wizard.domain-register-modal.upload-request-signed")}
                                      </SecondaryButton> */}

                                      {selectedFiles[product.domain] && (
                                        <div className={isMobileOnly ? 'col-12' : ''}>
                                          <StyledFileInfo>
                                            {selectedFiles[product.domain].name} ({selectedFiles[product.domain].size})
                                          </StyledFileInfo>
                                        </div>
                                      )}
                                    </StyledRow>
                                  </>
                                )}
                              </>
                            )}
                            {activeTabs[product.domain] === "option2" && (
                              <>
                                <StyledTabDescriptionText>
                                  {translate("cart.wizard.domain-register-modal.option2-description-1")}
                                  <strong>{translate("cart.wizard.domain-register-modal.option2-description-2")}</strong>
                                </StyledTabDescriptionText>
                                <PrimaryButton onClick={() => handleDownloadRequest(product)}>
                                  <img src={iconDownload} alt="" className="mr-2" />
                                  {translate("cart.wizard.domain-register-modal.download-request-for-sign")}
                                </PrimaryButton>
                                <StyledTabDescriptionText className="mt-4">
                                  <StyledRow>
                                    <Icon size="large" icon="warning" color="#fe9b34" />
                                    <StyledSmallText><strong>{translate("cart.wizard.domain-register-modal.option2-description-3")}</strong></StyledSmallText>
                                  </StyledRow>
                                  <br />
                                  <br />
                                  {translate("cart.wizard.domain-register-modal.enter-courier-details")}
                                </StyledTabDescriptionText>
                                <Form
                                  onSubmit={() => { }}
                                  initialValues={courierFormData[product.domain] || {}}
                                  validate={values => validateCourierDetails(values, product.domain)}
                                  keepDirtyOnReinitialize
                                  render={({ handleSubmit, values, form, invalid, pristine }) => (
                                    <form onSubmit={(e) => e.preventDefault()}>
                                      <StyledRow>
                                        <div className="col-xl-6 p-0 m-0">
                                          <Field
                                            name="name"
                                            component={InputField}
                                            label={translate("cart.wizard.domain-register-modal.name-and-surname")}
                                            required
                                            validate={validateFullName}
                                            onChange={(event) => handleCourierInputChange(product.domain, "name", event.target.value)}
                                            disabled={!isFormEditable[product.domain]}
                                          />
                                        </div>
                                        <div className="col-xl-6 p-0 m-0">
                                          <div className="col-12 col-sm-12 col-md-12 p-0 m-0">
                                            <PhoneNumberInput
                                              name="phone"
                                              label={translate("cart.wizard.phone-number")}
                                              placeholder={translate("cart.wizard.phone-number")}
                                              defaultCountry={'MK'}
                                              translate={translate}
                                              required
                                              disabled={!isFormEditable[product.domain]}
                                            />
                                          </div>
                                        </div>
                                      </StyledRow>
                                      <StyledRow>
                                        <div className="col-xl-6 p-0 m-0">
                                          <Field
                                            name="address"
                                            component={InputField}
                                            label={translate("cart.wizard.address")}
                                            required
                                            validate={validateAddress}
                                            onChange={(event) => handleCourierInputChange(product.domain, "address", event.target.value)}
                                            disabled={!isFormEditable[product.domain]}
                                          />
                                        </div>
                                        <div className="col-xl-6 p-0 m-0">
                                          <Field
                                            name="street"
                                            component={InputField}
                                            label={translate("domain.domain-contacts.appartment-number")}
                                            required
                                            validate={validateStreetNumber}
                                            onChange={(event) => handleCourierInputChange(product.domain, "street", event.target.value)}
                                            disabled={!isFormEditable[product.domain]}
                                          />
                                        </div>
                                      </StyledRow>
                                      <StyledRow>
                                        <div className="col-xl-6 p-0 m-0">
                                          <Field
                                            name="city"
                                            component={InputField}
                                            label={translate("cart.wizard.domain-register-modal.city")}
                                            required
                                            validate={validateCity}
                                            onChange={(event) => handleCourierInputChange(product.domain, "city", event.target.value)}
                                            disabled={!isFormEditable[product.domain]}
                                          />
                                        </div>
                                        <div className="col-xl-6 p-0 m-0">
                                          <Field
                                            name="postalCode"
                                            component={InputField}
                                            label={translate("cart.wizard.domain-register-modal.postal-code")}
                                            required
                                            validate={validateZipCode}
                                            onChange={(event) => handleCourierInputChange(product.domain, "postalCode", event.target.value)}
                                            disabled={!isFormEditable[product.domain]}
                                          />
                                        </div>
                                      </StyledRow>
                                      <StyledRowEnd className="mt-4 justify-end">
                                        {savedCourierData[product.domain] ? (
                                          <>
                                            <SecondaryButton
                                              onClick={() => handleEditCourierForm(product.domain)}
                                              disabled={isFormEditable[product.domain]}
                                            >
                                              {translate("cart.wizard.domain-register-modal.edit")}
                                            </SecondaryButton>
                                            <PrimaryButton
                                              onClick={() => handleSaveCourierForm(product.domain, values)}
                                              disabled={!isFormEditable[product.domain]}
                                            >
                                              {translate("cart.wizard.domain-register-modal.save")}
                                            </PrimaryButton>
                                          </>
                                        ) : (
                                          <PrimaryButton
                                            onClick={() => handleSaveCourierForm(product.domain, values)}
                                            disabled={invalid || pristine} // Updated condition
                                          >
                                            {translate("cart.wizard.domain-register-modal.save")}
                                          </PrimaryButton>
                                        )}
                                        {Object.keys(savedCourierData).length > 0 && !savedCourierData[product.domain] && (
                                          <SecondaryButton
                                            onClick={() => handleUseSameAddress(product.domain, Object.keys(savedCourierData)[0])}
                                          >
                                            {translate("cart.wizard.domain-register-modal.use-same-address")}
                                          </SecondaryButton>
                                        )}
                                      </StyledRowEnd>
                                    </form>
                                  )}
                                />
                              </>
                            )
                            }
                            {activeTabs[product.domain] === "option3" && (
                              <>
                                <StyledTabDescriptionText>
                                  {translate("cart.wizard.domain-register-modal.option3-description")}
                                </StyledTabDescriptionText>
                                <PrimaryButton
                                  onClick={() => handleDownloadRequest(product)}
                                >
                                  <img src={iconDownload} alt="" className="mr-2" />
                                  {translate("cart.wizard.domain-register-modal.download-request-for-sign")}
                                </PrimaryButton>
                              </>
                            )}
                          </TabContent>
                        </TabContainer>
                      </StyledWrapper>
                    </Container>
                  </div>

                ) : (
                  <div className="mt-5">
                    <Container>
                      <StyledWrapperNoBottomPadding>
                        <StyledText>{translate("cart.wizard.domain-register-modal.no-signing-required")}:   <StyledUnderline> {product.domain}</StyledUnderline></StyledText>
                        <StyledDescription>
                          {/* Со цел поедноставување на процесот, МКхост за тебе го подготви барањето согласно внесените податоци, а воедно овозможени се и повеќе начини на потпишување, достапни подолу: */}
                        </StyledDescription>
                      </StyledWrapperNoBottomPadding>
                    </Container>
                  </div>
                ))))}
            <StyledFooter>
              <SecondaryButton className={isMobileOnly ? "w-50" : ""} onClick={onGoBack}>{translate("cart.wizard.domain-register-modal.go-back")}</SecondaryButton>
              <PrimaryButton
                className={isMobileOnly ? "w-50" : ""}
                onClick={handleNextStep}
                submitting={isSaving}
                disabled={isSaving || !canProceed()} // Ensure it is disabled when submitting
              >
                {translate(isSaving ? "cart.wizard.domain-register-modal.saving" : "cart.wizard.domain-register-modal.next")}
              </PrimaryButton>


            </StyledFooter>
          </div>
        </div>
      )
      }
    </Translate >
  );
};

export default withRouter(withLocalize(DomainRegister));
