import React, { useEffect } from "react";
import styled from "styled-components";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import Radiobox from "../../components/Radiobox";
import InputField from "../../components/InputField";
import CustomTooltip from "../../components/CustomTooltip";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { theme } from "../../design/Themes";
import SecondaryButton from "../Buttons/SecondaryButton";
import { Field, Form } from "react-final-form";


const StledDnsDescription = styled.div`
  font-size: 14px;
  line-height: 1.3;
  color: ${theme.neutralBase};
  font-weight: 400;
  display: flex;
  margin-bottom: 10px;
`;
const StledDnsDescriptionBold = styled.div`
  font-size: 14px;
  line-height: 1.3;
  color: ${theme.neutralBase};
  font-weight: bold;
  padding-left: 5px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledCustomTooltip = styled.div`
  display: inline;
  margin-bottom: 5px
`;

const TabContainer = styled.div`
//   margin-top: 20px;
`;

const TabContent = styled.div`
  font-size: 14px;
  color: ${theme.neutral6};
`;

const DnsSettingsModal = ({
    isOpen,
    onClose,
    selectedOption,
    setSelectedOption,
    hosts,
    updateHost,
    addHost,
    removeHost,
    maxHosts,
    domainName,
    domain,
    onSaveDns,
    translate,
    doesHostnameMatchDomain,
    validateDnsSettings,
    defaultDns = {},
    setHosts
}) => {
    const [dnsFormErrors, setDnsFormErrors] = React.useState({});

    useEffect(() => {
        const recognizedOption = inferDnsOption(defaultDns);
        if (recognizedOption) {
            setSelectedOption(recognizedOption);
        }
    }, [defaultDns]);


    useEffect(() => {
        if (defaultDns) {
            const ns1 = (defaultDns.ns1 || "").trim().toLowerCase();
            const ns2 = (defaultDns.ns2 || "").trim().toLowerCase();
            const isPredefined =
                (ns1 === "ns.mkhost.mk" && ns2 === "ns.mkhost.org") ||
                (ns1 === "dns1.mk-host.mk" && ns2 === "dns2.mk-host.mk");

            if (!isPredefined) {
                const newHosts = [];
                for (let i = 1; i <= maxHosts; i++) {
                    const nsValue = (defaultDns[`ns${i}`] || "").trim();
                    if (nsValue !== "") {
                        newHosts.push({
                            id: Date.now() + i,
                            name: `${translate("cart.wizard.account-info-modal.option3.ns-name-of-host")} ${i}`,
                            value: nsValue,
                            address: (defaultDns[`ip${i}`] || "").trim(),
                            fixed: true,
                        });
                    }
                }
                setHosts(newHosts);
            }
        }
    }, [defaultDns, selectedOption]);


    const inferDnsOption = (dnsConfig) => {
        if (!dnsConfig) return null;

        const ns1 = (dnsConfig.ns1 || "").trim().toLowerCase();
        const ns2 = (dnsConfig.ns2 || "").trim().toLowerCase();

        // Predefined hosts for the hosted option
        if (ns1 === "ns.mkhost.mk" && ns2 === "ns.mkhost.org") {
            return "hostedInMkhost";
        }
        // Predefined nameservers for the custom DNS zone option
        if (ns1 === "dns1.mk-host.mk" && ns2 === "dns2.mk-host.mk") {
            return "customDnsZone";
        }
        // Otherwise, assume custom
        return "customDnsServers";
    };

    const initialDnsValues = React.useMemo(() => {
        const recognizedOption = inferDnsOption(defaultDns);
        const isPredefined =
            recognizedOption === "hostedInMkhost" ||
            recognizedOption === "customDnsZone";

        return {
            dnsServer1: isPredefined ? "" : defaultDns.ns1 || "",
            dnsServer2: isPredefined ? "" : defaultDns.ns2 || "",
            dnsServer3: isPredefined ? "" : defaultDns.ns3 || "",
            dnsServer4: isPredefined ? "" : defaultDns.ns4 || "",
            dnsServer5: isPredefined ? "" : defaultDns.ns5 || "",
            dnsAddress1: isPredefined ? "" : defaultDns.ip1 || "",
            dnsAddress2: isPredefined ? "" : defaultDns.ip2 || "",
            dnsAddress3: isPredefined ? "" : defaultDns.ip3 || "",
            dnsAddress4: isPredefined ? "" : defaultDns.ip4 || "",
            dnsAddress5: isPredefined ? "" : defaultDns.ip5 || "",
        };
    }, [defaultDns]);
    return (
        <Modal
            isOpen={isOpen}
            onCloseModal={onClose}
            title={(translate("cart.wizard.edit-nameservers-for")) + " " + domainName}
            size="xl"
            body={() => (
                <>
                    <TabContainer>
                        <TabContent>
                            <StledDnsDescription>{translate("cart.wizard.account-info-modal.choose-one-option")}</StledDnsDescription>

                            {[
                                {
                                    id: "customDnsZone",
                                    label: translate("cart.wizard.account-info-modal.option3.label-1"),
                                    description: translate("cart.wizard.account-info-modal.option3.description-1"),
                                    tooltip: translate("cart.wizard.account-info-modal.option3.tooltip-1"),
                                },
                                {
                                    id: "hostedInMkhost",
                                    label: translate("cart.wizard.account-info-modal.option3.label-2"),
                                    description: translate("cart.wizard.account-info-modal.option3.description-2"),
                                    tooltip: translate("cart.wizard.account-info-modal.option3.tooltip-2"),
                                },
                                {
                                    id: "customDnsServers",
                                    label: translate("cart.wizard.account-info-modal.option3.label-3"),
                                    description: translate("cart.wizard.account-info-modal.option3.description-3"),
                                    tooltip: translate("cart.wizard.account-info-modal.option3.tooltip-3"),
                                },
                            ].map((option) => (
                                <div key={option.id}>
                                    <StyledRow>
                                        <Radiobox
                                            name="dnsOptions"
                                            value={option.id}
                                            onChange={() => setSelectedOption(option.id)}
                                            checked={selectedOption === option.id}
                                        >
                                            {option.label}
                                        </Radiobox>
                                        <StyledCustomTooltip>
                                            <CustomTooltip content={option.tooltip}>
                                                <Icon size="s" icon="info" />
                                            </CustomTooltip>
                                        </StyledCustomTooltip>
                                    </StyledRow>

                                    {selectedOption === option.id && (
                                        <div className="ml-4 mt-0 mb-3">
                                            {option.id === "hostedInMkhost" && (
                                                <>
                                                    <StledDnsDescription>
                                                        {translate("cart.wizard.account-info-modal.option3.ns-label")} 1:{" "}
                                                        <StledDnsDescriptionBold>ns.mkhost.mk</StledDnsDescriptionBold>
                                                    </StledDnsDescription>
                                                    <StledDnsDescription>
                                                        {translate("cart.wizard.account-info-modal.option3.ns-label")} 2:{" "}
                                                        <StledDnsDescriptionBold>ns.mkhost.org</StledDnsDescriptionBold>
                                                    </StledDnsDescription>
                                                </>
                                            )}
                                            {option.id === "customDnsZone" && (
                                                <>
                                                    <StledDnsDescription>
                                                        {translate("cart.wizard.account-info-modal.option3.ns-label")} 1:{" "}
                                                        <StledDnsDescriptionBold>dns1.mk-host.mk</StledDnsDescriptionBold>
                                                    </StledDnsDescription>
                                                    <StledDnsDescription>
                                                        {translate("cart.wizard.account-info-modal.option3.ns-label")} 2:{" "}
                                                        <StledDnsDescriptionBold>dns2.mk-host.mk</StledDnsDescriptionBold>
                                                    </StledDnsDescription>
                                                </>
                                            )}
                                            {option.id === "customDnsServers" && (
                                                <Form
                                                    onSubmit={(values) => {
                                                        console.log("Form values", values);
                                                    }}
                                                    initialValues={initialDnsValues}
                                                    validate={(values) => {
                                                        const errors = selectedOption === "customDnsServers"
                                                            ? validateDnsSettings(values, hosts, domainName, translate, doesHostnameMatchDomain)
                                                            : {};

                                                        if (JSON.stringify(errors) !== JSON.stringify(dnsFormErrors)) {
                                                            setDnsFormErrors(errors);
                                                        }
                                                        return errors;
                                                    }}
                                                    enableReinitialize={true}
                                                    render={({ handleSubmit }) => (
                                                        <form onSubmit={handleSubmit}>
                                                            {hosts.map((host, index) => (
                                                                <div className="row" key={host.id}>
                                                                    <div className="col-xl-5">
                                                                        <Field
                                                                            component={InputField}
                                                                            label={`${translate("cart.wizard.account-info-modal.option3.ns-name-of-host")} ${index + 1}`}
                                                                            name={`dnsServer${index + 1}`}
                                                                            className="w-100"
                                                                            required
                                                                            parse={(value) => {
                                                                                updateHost(host.id, "value", value); // Sync local state
                                                                                return value;
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {doesHostnameMatchDomain(host.value, domainName) && (
                                                                        <div className="col-xl-5">
                                                                            <Field
                                                                                component={InputField}
                                                                                label={`${translate("cart.wizard.account-info-modal.option3.address-placeholder")} ${index + 1}`}
                                                                                name={`dnsAddress${index + 1}`}
                                                                                className="w-100"
                                                                                required={doesHostnameMatchDomain(host.value, domainName)}
                                                                                validate={(value) => {
                                                                                    if (doesHostnameMatchDomain(host.value, domainName)) {
                                                                                        if (!value || value.trim() === "") {
                                                                                            return translate("cart.wizard.account-info-modal.validation.required-ip");
                                                                                        }

                                                                                        const ipRegex = /^(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?!$)|$)){4}$/;
                                                                                        if (!ipRegex.test(value.trim())) {
                                                                                            return translate("cart.wizard.account-info-modal.validation.invalid-ip-format");
                                                                                        }
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                                parse={(value) => {
                                                                                    updateHost(host.id, "address", value);
                                                                                    return value;
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {!host.fixed && (
                                                                        <div className="col-xl-2 mt-4">
                                                                            <button type="button" className="btn btn-danger" onClick={() => removeHost(host.id)}>
                                                                                X
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                            {hosts.length < maxHosts && (
                                                                <div className="text-right mt-3">
                                                                    <SecondaryButton onClick={addHost}>
                                                                        + {translate("cart.wizard.account-info-modal.option3.add-more-ns")}
                                                                    </SecondaryButton>
                                                                </div>
                                                            )}
                                                        </form>
                                                    )}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </TabContent>
                    </TabContainer>
                    <div className="mt-3 d-flex justify-content-end">
                        <PrimaryButton
                            onClick={() => onSaveDns(domain)}
                            disabled={
                                selectedOption === "customDnsServers" &&
                                Object.keys(dnsFormErrors).length > 0
                            }
                        >
                            {translate("cart.wizard.account-info-modal.option3.save-dns")}
                        </PrimaryButton>
                    </div>
                </>
            )}
        />
    )
}

export default DnsSettingsModal;
